@import './comments-ford.scss';

.brand-lincoln {
    .light-specific-service-wrapper
        .light-journey-specific-page
        .light-specific-service
        .ss-comments
        .comments-section-title {
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }
    .comment-container {
        .comments-section-title {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .comments-body {
            .comments-header {
                font-family: $proxima-nova-regular;
                color: $fm-text-grey;
                font-size: 14px;
                line-height: 20px;
                ul {
                    li {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                }
            }
            .comments-remaining-char {
                font-family: $proxima-nova-regular;
                color: $darkgrey;
            }
        }

        .comment-input textarea {
            border: 1px solid #6e6e6e;
            background-color: #fefefe;
            font-family: $proxima-nova-regular;
        }
        .comment-input {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .comment-empty-error {
            border: solid 1px $brand-light-error !important;
        }
        .empty-commments-error {
            font-family: $proxima-nova-regular;
            letter-spacing: 1px;
            color: $brand-light-error;
        }
    }
}
