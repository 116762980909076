@import './light-booking-confirmation-ford.scss';

.brand-lincoln {
    .osb-light-booking-confirmation-outer-div {
        .osb-booking-confirmatin-header-section {
            .bc-header {
                font-family: $proxima-nova-regular;
                color: #000;
            }
            .bc-sub-header {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
        }
        .osb-light-booking-confirmation-container {
            .what-happens-next-container {
                .what-happens-next-section {
                    .what-happens-next-title {
                        font-family: $proxima-nova-regular;
                        color: #000;
                    }
                    .what-happens-next-desc {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;

                        .link-style {
                            color: #4f4c43;
                        }
                    }
                }
                .manage-your-booking-section {
                    .manage-your-booking-title {
                        font-family: $proxima-nova-regular;
                        color: #000;
                    }
                    .manage-your-booking-desc {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                        p {
                            a {
                                color: #4f4c43;
                                font-family: $proxima-nova-bold;
                            }
                        }
                    }
                }
                .ford-account-section {
                    .ford-account-title {
                        font-family: $proxima-nova-regular;
                        color: #000;
                    }
                    .ford-account-desc {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                    .button-container {
                        .or-style {
                            color: $brand-secondary;
                        }
                        .login-btn {
                            font-family: $proxima-nova-regular;
                        }
                    }
                }
            }
        }
    }
}
