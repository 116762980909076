@import '../../../../../styles/main.scss';

.osb-overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
}
