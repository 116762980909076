@import '../../../../styles/main.scss';
/* GENERAL */
* {
    box-sizing: border-box;
}
.new-osb-calendar-container {
    @include tablet-portrait {
        width: 100%;
        order: 4;
    }
    .cal-main-head {
        font-family: $f1-font-regular;
        font-size: 110%;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: #4d4d4d;
        margin-bottom: 20px;
        white-space: break-spaces;
        @include mobile {
            text-align: left;
        }
    }
    .icon {
        font-style: normal;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
    }
    body {
        font-size: 1em;
        line-height: 1.5;
        position: relative;
    }
    /* GRID */
    .row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .row-middle {
        align-items: center;
    }
    .column {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }
    /* Calendar */
    .calendar {
        display: block;
        position: relative;
        border: 1px solid $fds-color-lt-gray-b;
        min-height: 280px !important;
        border-radius: 12px;
        padding: 0 10px 10px 10px;
        background-color: $fds-color-white;

        @include mobile {
            margin-top: 2px;
        }
        @media only screen and (max-width: 900px) {
            width: 100%;
        }
        @include tablet-portrait {
            width: 100%;
            height: auto !important;
            padding-bottom: 10px;
        }
    }
    .calendar .cal-header {
        margin-top: 15px;
        margin-bottom: 15px;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.91;
        text-align: center;
        color: $fm-text-grey;

        @include tablet-portrait {
            font-size: 140%;
        }
    }
    .calendar .cal-header .icon {
        cursor: pointer;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.25rem;
        font-size: 22px;
        font-family: $f1-font-light;
        color: $fm-breadcrumb-blue;
    }

    .calendar .days {
        text-transform: uppercase;
        padding: 10px 0;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.91;
        text-align: center;
        color: $fm-text-grey;
        @include tablet-portrait {
            font-size: 120%;
        }
    }
    .calendar .body {
        padding: 5px;
    }
    .calendar .body .cell {
        position: relative;
        height: 34px;
        overflow: hidden;
        cursor: pointer;
        transition: 0.25s ease-out;
        font-size: 14px;
        font-weight: 400;
        color: $fm-text-grey;
        @include tablet-portrait {
            font-size: 120%;
            color: gray;
        }
    }
    .calendar .body .cell:hover {
        background: $fm-breadcrumb-blue;
        color: #f4f4f4;
        border-radius: 5px;
        top: 0px;
        .number {
            bottom: 13px;
        }
    }

    .calendar .body .currentDay .number {
        border-radius: 50%;
        background: $fm-breadcrumb-blue;
        width: 25px;
        height: 25px;
        padding-top: 8px;
        top: 14px;
        margin: auto;
        color: #fff;
    }

    .calendar .body .selected {
        color: $fm-breadcrumb-blue;
        background: none;
        border: solid 1px $fm-breadcrumb-blue;
        border-radius: 5px;
        font-family: $f1-font-regular;
        top: 1px;
    }

    .calendar .body .row:last-child {
        border-bottom: none;
    }

    .calendar .body .cell .number {
        position: absolute;
        text-align: center;
        font-size: 14px;
        line-height: 1;
        left: 10px;
        bottom: 13px;
        right: 0.75em;
        // font-weight: 700;
        font-family: $f1-font-regular;
    }

    .calendar .body .selected .number {
        // bottom: 12px;
        left: 8px;
    }

    .calendar .body .disabled {
        color: $grey;
        pointer-events: none;
    }
    .calendar .body .cell .bg {
        // font-weight: 700;
        line-height: 1;
        color: #4d4d4d;
        opacity: 0;
        font-size: 11px;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        font-family: $f1-font-regular;
    }

    .calendar .body .column {
        flex-grow: 0;
        flex-basis: calc(100% / 7);
        width: calc(100% / 7);
        height: 40px;
    }
}
