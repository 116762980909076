@import './light-osb-home-retrieve-booking-ford.scss';

.brand-lincoln {
    .osb-home-retrieve-booking-container {
        .osb-home-retrieve-booking-header {
            color: #324047;
            font-family: $proxima-nova-regular;
        }
        .osb-home-retrieve-booking-copy-text {
            color: #324047;
            font-family: $proxima-nova-regular;
        }
    }
}
