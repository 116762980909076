@import './dealer-more-info-ford.scss';
.brand-lincoln {
    .osb-light-journey-dealer-more-info-container {
        font-family: $proxima-nova-regular;
        .dealer-more-detail {
            .osb-dealer-distance {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
            .call-dealer-popup {
                .call-dealer-link {
                    color: #4f4c43;
                    font-family: $proxima-nova-regular;
                }
            }
            .special-service-provided {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .Earliest-available-slot {
                background-color: $brand-secondary;
                font-family: $proxima-nova-regular;
                color: #fff;
            }

            .working-hours {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .osb-dealer-details {
                .sequence-number {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .osb-dealer-name {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .osb-dealer-address {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }
        }
    }

    .dealer-call-cta-popup-content {
        border: 1px solid $brand-secondary;
        color: $brand-secondary;
        font-family: $proxima-nova-regular;
        box-shadow: 1px 10px 15px #c8c5c4;
    }

    .new-dealer-more-info-section {
        .dealer-more-info {
            .dealer-more-detail {
                .osb-dealer-details-view {
                    font-family: $proxima-nova-regular;
                    .osb-dealer-index {
                        .sequence-number {
                            color: $brand-secondary;
                        }
                        .preferred-dealer-title {
                            color: $fds-color-white;
                            background-color: $fds-color-secondary;
                        }
                    }

                    .osb-dealer-detail-section {
                        .osb-dealer-details {
                            .osb-dealer-name {
                                color: $brand-secondary;
                            }
                            .osb-dealer-address {
                                color: $fds-color-dk-gray;
                            }
                        }
                    }
                    .dealer-other-details {
                        .osb-dealer-distance {
                            color: $fds-color-dk-gray;
                        }
                        .Earliest-available-slot {
                            background-color: $fds-color-primary;
                            color: $fds-color-white;
                        }
                        .opening-hours-title,
                        .dealer-capability-title {
                            color: $brand-secondary;
                        }
                        .working-hours {
                            color: $fds-color-dk-gray;
                        }
                        .dealer-vehicle-services {
                            .dealer-capability-text {
                                color: $fds-color-dk-gray;
                            }
                        }
                        .special-service-provided-title {
                            color: $brand-secondary;
                        }
                        .service-provided-list {
                            color: $fds-color-dk-gray;
                        }
                    }
                }
            }
        }
    }
}
