@import './light-cancel-booking-confirmation.ford.scss'; // Default FDS style

.brand-lincoln {
    .light-cancel-booking-confirmation-component-container {
        .cbc-head {
            color: #4d4d4d;
            font-family: $proxima-nova-regular;
        }
        .cbc-text {
            font-family: $proxima-nova-regular;
        }
        .cbc-box-content {
            .body-content {
                p {
                    font-family: $proxima-nova-regular;
                }
            }
        }
        .cbc-footer-content-container {
            .cbc-footer-rebook {
                .sub-head {
                    font-family: $proxima-nova-regular;
                    color: #4d4d4d;
                }
                .rebook-text {
                    font-family: $proxima-nova-regular;
                    span > p > a {
                        color: #4f4c43;
                    }
                }
            }
        }
        .cbc-footer-ford-account {
            .ford-account-section {
                .ford-account-title {
                    font-family: $proxima-nova-regular;
                    color: #4d4d4d;
                }
                .ford-desc-container {
                    font-family: $proxima-nova-regular;
                }
            }
        }
    }
}
