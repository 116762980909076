@import '../../../../../styles/main.scss';

.osb-video-image-overlay-content {
    background: #fff;
    width: 55%;
    min-height: 50%;
    border-radius: 3px;
    padding: 0;
    height: 78%;

    @include tablet-portrait {
        width: 100%;
        height: 100%;
    }
    .osb-video-image-content-container {
        width: 98%;
        height: 95%;
        margin: 0 auto;
        position: relative;
        .overlay-header {
            .osb-secondary-button {
                padding: 0;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 8px;
            }

            .overlay-button-tray {
                display: flex;
                justify-content: flex-end;
                ul {
                    cursor: pointer;
                    list-style: none;

                    li.close {
                        padding-right: 5px;
                        color: #7d7d7d;
                        font-size: 200%;
                    }
                }
            }
        }

        .osb-video-image-title {
            max-width: 980px;
            font-family: $f1-font-regular;
            font-size: 24px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            color: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: left;
            margin-top: 20px;
            padding: 10px 10px 10px 50px;
        }
    }
    .osb-add-video-style {
        @include tablet-portrait {
            width: 90%;
            height: 40%;
        }
    }
}

.osb-video-image-overlay-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 2010 !important;
}
.popup-content::-webkit-scrollbar {
    width: 5px;
}

.popup-content::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #4d4d4d;
}
