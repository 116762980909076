@import '../../../../../../styles/main.scss';

.osb-light-journey-dealer-more-info-container {
    width: 100%;
    font-family: $f1-font-regular;

    @include mobile {
        width: 100% !important;
    }

    span.close-icon {
        width: 16px;
        height: 16px;
        margin: 9px 229px 2px 95%;
    }

    .dealer-more-info {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    .dealer-map-view {
        width: 50%;
        height: auto;
        margin-right: 40px;
        @include mobile {
            width: 100%;
            height: 320px;
            order: 2;
            margin-top: 25px;
        }
        .osb-map-container {
            height: 100%;
            width: 100%;
        }
    }
    .dealer-more-detail {
        width: 50%;
        .osb-secondary-button {
            padding: 47px 0 38px 5px;
            @include mobile {
                padding: 10px 0 25px 0;
            }
            .fds-chevron-wrap {
                margin: 7px 3px 0 0;
            }
        }

        @include mobile {
            width: 100%;
            margin: 0;
            padding: 30px 30px 30px 20px;
        }

        .osb-dealer-distance {
            display: flex;
            font-size: 12px;
            font-family: $f1-font-regular;
            line-height: 1;
            color: #4d4d4d;
            @include mobile {
                font-size: 11px;
                line-height: 1.91;
            }
            .distance-unit {
                margin-left: 5px;
            }
        }
        .call-dealer-popup {
            width: -moz-fit-content;
            width: fit-content;
            margin: 20px 0;
            .call-dealer-link {
                color: $fm-skyview;
                font-size: 16px;
                text-decoration: underline;
                cursor: pointer;
                font-family: $f1-font-regular;
                line-height: 1.63;
                @include mobile {
                    font-size: 12px;
                    line-height: 2.17;
                }
            }
        }
        .dealer-vehicle-services {
            margin: 25px auto;
            .service-name {
                font-size: 12px;
                font-family: $f1-font-regular;
                color: #4d4d4d;
                padding: 5px 0;
                display: flex;
                img {
                    height: 16px;
                    width: 16px;
                    margin-right: 10px;
                }
            }
        }
        .special-service-provided {
            font-family: $f1-font-regular;
            @include xs-mobile {
                font-size: 13px;
            }
            .special-service-provided-title {
                font-size: 12px;
                color: #4d4d4d;
                font-family: $f1-font-regular;
                margin-bottom: 15px;
            }
            .service-provided-list {
                padding: 0 25px;
                font-family: $f1-font-regular;
                font-size: 12px;
                color: #4d4d4d;
                .service-provided-item {
                    list-style-type: square;
                    margin-bottom: 10px;
                }
            }
        }
        .Earliest-available-slot {
            width: fit-content;
            background-color: #1700f4;
            font-size: 12px;
            font-family: $f1-font-regular;
            text-align: left;
            border-radius: 3px;
            color: #fff;
            line-height: 1.91;
            margin: 10px 0 32px 0;
            padding: 5px 10px 5px 5px;

            @media only screen and (max-width: 360px) {
                font-size: 10px;
                padding: 3px 0;
            }
        }
        .osb-dealer-select-button {
            margin: 20px 0 30px 0;
            @include mobile {
                margin: 30px 0 42px 0;
                display: flex;
                justify-content: center;
            }
            .primary-button {
                max-width: 172px;
                justify-content: center;
                padding: 0px 25px;
                @include mobile {
                    max-width: 240px;
                }
            }
            .button-label {
                font-size: 18px;
                font-family: $f1-font-regular;
                line-height: 1.25;
                color: #fff;
                white-space: break-spaces;
                padding: 12px 0px;
            }
        }
        .working-hours {
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 1.63;
            color: #4d4d4d;
            font-family: $f1-font-regular;
            @include mobile {
                font-size: 11px;
                line-height: 1.91;
            }
        }
        .osb-dealer-details {
            display: grid;
            grid-template-columns: 8% 92%;
            @include mobile {
                grid-template-columns: 12% 88%;
            }

            .sequence-number {
                font-size: 48px;
                line-height: 1.21;
                text-align: center;
                color: #00095b;
                margin-right: 19px;
                font-family: $f1-font-regular;
                @include mobile {
                    font-size: 34px;
                    line-height: 1.29;
                    margin-right: 10px;
                }
            }
            .osb-dealer-name {
                margin: 4px 0 8px 0;
                font-size: 16px;
                font-family: $f1-font-regular;
                line-height: 1.63;
                color: #00095b;
                text-transform: uppercase;
                white-space: break-spaces;
            }
            .osb-dealer-address {
                margin: 0 0 10px 0;
                font-size: 16px;
                font-family: $f1-font-regular;
                white-space: nowrap;
                line-height: 1.63;
                color: #4d4d4d;
                text-transform: none;
                @include mobile {
                    font-size: 12px;
                    white-space: break-spaces;
                    line-height: 1.75;
                }
            }
        }

        .dealer-other-details {
            text-transform: none;
        }
    }
}

.dealer-call-cta-popup-content {
    width: 180px !important;
    height: 50px !important;
    padding: 13px 13px 13px 30px !important;
    background-color: #ffffff !important;
    border: 1px solid #949699;
    color: #4d4d4d;
    border-radius: 5px;
    font-size: 16px;
    font-family: $f1-font-regular;
    margin-left: 10px;
    box-shadow: 1px 10px 15px #c8c5c4;
}

.dealer-call-cta-popup-arrow {
    color: #fff !important;
    stroke-width: 2px !important;
    stroke: #6e6e6e !important;
    stroke-dasharray: 30px !important;
    stroke-dashoffset: -54px !important;
}

.new-dealer-more-info-section {
    padding: 50px;
    @include mobile {
        padding: 0;
    }
    .back-to-dealer-list {
        margin: 0 0 25px 0px;
        .osb-secondary-button {
            padding: 0 0 20px 0px;
            @include mobile {
                padding: 20px 25px;
            }
        }
    }
    .dealer-more-info {
        display: flex;
        column-gap: 30px;
        @include mobile {
            flex-direction: column;
        }
        .dealer-map-view {
            width: 60%;
            height: 407px;
            margin-right: 40px;
            @include mobile {
                width: 100%;
                height: 320px;
                order: 2;
                margin: 20px 0;
            }
            .osb-map-container {
                height: 100%;
                width: 100%;
            }
        }
        .dealer-more-detail {
            width: 40%;
            @include mobile {
                width: 100%;
                padding: 25px;
            }
            .osb-dealer-details-view {
                .osb-dealer-index,
                .osb-dealer-details {
                    .osb-dealer-name {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 32px;
                        font-weight: 500;
                        text-transform: capitalize;
                        white-space: break-spaces;
                        @include mobile {
                            font-size: 20px;
                            line-height: normal;
                        }
                    }
                }
                .osb-dealer-index {
                    display: flex;
                    column-gap: 30px;
                    .sequence-number {
                        color: $fds-color-primary;
                        font-family: $f1-font-regular;
                        font-size: 32px;
                    }
                    .preferred-dealer-title {
                        color: $fds-color-white;
                        font-family: $f1-font-regular;
                        width: fit-content;
                        background-color: $fds-color-primary;
                        padding: 5px 10px;
                        text-align: center;
                        font-size: 11px;
                        line-height: 17px;
                        text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                        border-radius: 10px;
                    }
                }

                .osb-dealer-detail-section {
                    display: flex;
                    margin-top: 20px;
                    @include mobile {
                        flex-direction: column;
                    }
                    .osb-dealer-details {
                        width: 60%;
                        @include mobile {
                            width: 100%;
                        }

                        .osb-dealer-address {
                            margin: 6px 0;
                            font-size: 14px;
                            font-family: $f1-font-regular;
                            white-space: nowrap;
                            line-height: 1.63;
                            color: $fm-text-grey;
                            text-transform: none;
                            @include mobile {
                                font-size: 16px;
                            }

                            @include mobile {
                                font-size: 12px;
                                white-space: break-spaces;
                                line-height: 1.75;
                            }
                        }
                    }
                }

                .dealer-other-details {
                    .osb-dealer-distance {
                        display: flex;
                        color: $fm-text-grey;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 10px;
                        .distance-unit {
                            margin-left: 10px;
                        }
                    }
                    .Earliest-available-slot {
                        width: fit-content;
                        background-color: $lightgrey;
                        color: $fm-text-grey;
                        font-size: 11px;
                        font-family: $f1-font-regular;
                        text-align: center;
                        border-radius: 5px;
                        margin: 10px 0 32px 0;
                        padding: 0 10px;
                        line-height: 21px;
                        text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                        @media only screen and (max-width: 360px) {
                            font-size: 10px;
                        }
                    }
                    .osb-dealer-select-cta {
                        width: 100%;
                        margin-bottom: 30px;
                        padding-bottom: 20px;
                        button {
                            width: 50%;
                            @include mobile {
                                width: 100%;
                            }
                        }
                        @include mobile {
                            margin: 0 0 20px 0;
                        }
                    }
                    .opening-hours-title,
                    .dealer-capability-title {
                        color: $fds-color-primary;
                        font-family: $f1-font-bold;
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 15px;
                    }
                    .working-hours {
                        color: $fm-text-grey;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 18px;
                        margin: 15px 0;
                        div {
                            margin: 10px 0;
                        }
                    }
                    .dealer-vehicle-services {
                        margin: 25px auto;
                        .dealer-capability-text {
                            font-size: 12px;
                            font-family: $f1-font-regular;
                            color: $fm-text-grey;
                            display: flex;
                            line-height: 18px;
                        }
                    }
                    .special-service-provided-title {
                        color: $fds-color-primary;
                        font-family: $f1-font-bold;
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 20px;
                    }
                    .service-provided-list {
                        color: $fm-text-grey;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 20px;
                        padding: 0 25px;
                        list-style-image: url('../../../../../../assets/owners-osb/tick-mark.svg');
                        .service-provided-item {
                            margin-bottom: 0px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
