@import '../../../../../../styles/main.scss';
.osb-dealer-details-desktop-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .osb-map-container {
        height: 610px;
    }
    .dealer-tiles-container {
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        position: absolute;
        top: 285px;
        left: 0;
        width: 100%;
        z-index: 0;
    }
    .view-more-container-mobile {
        margin-top: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .view-more-button-mobile {
            display: flex;
            align-items: center;
            background-color: $fds-color-white;
            border-radius: 20px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 20px;
            margin-bottom: 30px;
            width: 139px;
            min-height: 40px;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .view-more-text-mobile {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 18px;
                line-height: 24px;
                padding-right: 15px;
            }
        }
    }
    .margin-top {
        margin-top: 30px;
        padding-top: 30px;
    }
    .search-list-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 460px;
        height: 100%;
        background-color: transparent;
        z-index: 1;
        overflow-y: auto;
        margin-right: 20px;
        .filter-container {
            margin-top: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        .filter-button {
            display: flex;
            align-items: center;
            background-color: $fds-color-white;
            border: 1px solid $fm-breadcrumb-blue;
            color: $fm-breadcrumb-blue;
            border-radius: 20px;
            padding: 6px;
            font-size: 14px;
            cursor: pointer;
            margin-right: 20px;
            margin-bottom: 30px;
            min-height: 30px;
            min-width: 100px;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .filters-text {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 24px;
                font-weight: 700;
                margin: auto;
            }
        }

        .filter-button img {
            margin: 0 5px;
        }
        .dealer-info-box-wrapper {
            background-color: $fds-color-white;
            border-radius: 10px;
            padding: 10px 5px 20px 20px;
            margin-bottom: 28px;
            margin-right: 5px;
            position: relative;
            width: 98%;
            .preferred-dealer-label {
                display: flex;
                background-color: $fds-color-primary;
                color: $fds-color-white;
                font-family: $f1-font-regular;
                font-size: 12px;
                border-radius: 10px;
                padding: 5px;
                position: absolute;
                top: -29px;
                left: 0;
                margin-top: 5px;
                box-sizing: border-box;
                height: 36px;
                padding-left: 15px;
                padding-right: 15px;
                z-index: -1;
            }
        }
        .dealer-info-box:first-of-type {
            margin-top: 20px;
        }

        .view-more-container {
            margin: 20px 0;
            display: flex;
            align-items: center;
            .view-more-button {
                display: flex;
                align-items: center;
                background-color: $fds-color-white;
                border-radius: 20px;
                padding: 10px;
                font-size: 14px;
                cursor: pointer;
                border: 1px solid $fm-breadcrumb-blue;
                color: $fm-breadcrumb-blue;
                height: 40px;
                box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                    0px 0px 6px rgba(0, 0, 0, 0.2);
                .view-more-text {
                    color: $fm-breadcrumb-blue;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 24px;
                    padding-right: 15px;
                }
            }
        }
        /* Customize the scrollbar */
        &::-webkit-scrollbar {
            width: 8px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: $fds-color-lt-gray;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $quicklink-textactive;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $quicklink-textactive;
        }
        .dealer-filter-options-container {
            background-color: $fds-color-white;
            height: 610px;
        }
    }
    .map-actions {
        position: relative;
        top: 10%;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 10px;
        &.fixed {
            position: fixed;
            top: 41px;
        }
        .filter-link,
        .list-link {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $fds-color-white;
            color: $fds-color-primary;
            border-radius: 20px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 14px;
            margin-bottom: 30px;
            width: 40%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .filters-text,
            .list-text {
                margin-left: 10px;
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 18px;
            }
        }
    }
}
