@import '../../../../../styles/main.scss';

.osb-light-booking-confirmation-outer-div {
    width: 100%;
    padding: 5% 0;
    @include mobile {
        padding: 0;
    }
    .osb-booking-confirmatin-header-section {
        margin: 30px 0;
        @include mobile {
            padding: 5%;
        }
        .bc-header {
            font-family: $f1-font-light;
            font-size: 40px;
            line-height: 48px;
            text-align: start;
            color: $fds-color-primary;
            @include mobile {
                margin: 0;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
            }

            @media print {
                display: none;
            }
        }
        .bc-sub-header {
            margin-top: 24px;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 1.63;
            text-align: start;
            color: #4d4d4d;
            @media print {
                display: none;
            }
            @include mobile {
                text-align: center;
            }
        }
        .hr-line-style {
            content: ' ';
            display: block;
            border-bottom: 1px solid $fds-color-lt-gray-b;
            margin: 30px 0 0;
        }
    }

    .osb-light-booking-confirmation-container {
        display: grid;
        grid-template-columns: 65% 33%;
        grid-column-gap: 2%;

        @media print {
            grid-template-columns: 100%;
            display: block;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            margin: 0;
        }

        .what-happens-next-container {
            grid-column: 1;
            display: flex;
            flex-direction: column;
            background-color: $scard-light-grey;
            border-radius: 16px;
            padding: 5%;
            @media print {
                display: none;
            }
            @include mobile {
                padding: 5%;
                order: 2;
                background-color: $fds-color-white;
            }

            .what-happens-next-section {
                .what-happens-next-title {
                    font-family: $f1-font-medium;
                    font-size: 24px;
                    line-height: 32px;
                    color: $fds-color-primary;
                    @include mobile {
                        font-size: 20px;
                        line-height: 1.5;
                        margin-top: 40px;
                    }
                }
                .what-happens-next-desc {
                    width: 100%;
                    margin: 16px 0 40px;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                    @include mobile {
                        margin: 16px 0 35px;
                    }
                    span {
                        p {
                            display: inline;
                        }
                    }
                    .call-dealer-popup {
                        width: -moz-fit-content;
                        width: fit-content;
                        display: inline-block;
                    }
                    .link-style {
                        color: $fds-color-primary;
                        font-size: 16px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
            .manage-your-booking-section {
                .manage-your-booking-title {
                    width: 100%;
                    font-family: $f1-font-medium;
                    font-size: 23px;
                    line-height: 32px;
                    color: $fds-color-primary;
                    @include mobile {
                        font-size: 20px;
                        line-height: 1.5;
                    }
                }
                .manage-your-booking-desc {
                    width: 100%;
                    margin: 16px 0 20px;
                    font-family: $f1-font-regular;
                    display: flex;
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                    float: left;
                    @include mobile {
                        margin: 16px 0 35px;
                    }
                    p {
                        display: inline;
                        a {
                            color: $fds-color-primary;
                            cursor: pointer;
                        }
                    }
                }
            }
            .ford-account-section {
                .ford-account-title {
                    font-family: $f1-font-medium;
                    font-size: 24px;
                    line-height: 32px;
                    color: $fds-color-primary;
                    @include mobile {
                        font-size: 20px;
                        line-height: 1.5;
                    }
                }
                .ford-account-desc {
                    width: 100%;
                    margin: 16px 0 10px;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 32px;
                    color: $fds-color-primary;
                }
                .button-container {
                    width: 100%;
                    padding-top: 22px;
                    display: flex;
                    flex-direction: row;
                    @include mobile {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .or-style {
                        margin: 4px 27px 10px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: $fds-color-primary;
                        @include mobile {
                            text-align: center;
                        }
                    }
                    .login-btn {
                        font-family: $f1-font-regular;
                        font-size: 18px;
                        line-height: 0.72;
                        text-align: center;
                        color: #ffffff;
                    }
                    .register-btn {
                        display: flex;
                        color: $fds-color-primary;
                        text-decoration: none;
                        p {
                            margin-right: 10px;
                            padding-top: 8px;
                        }
                    }
                    .primary-button {
                        @include mobile {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
    // .osb-loading {
    //     .fds-activity-indicator {
    //         //top: 40%;
    //         padding-top: 0;
    //     }
    // }
}

.booking-confirm-dealer-popup-content {
    width: 300px !important;
    height: 247px !important;
    margin: 250px auto !important;
    padding: 10px 15.5px 86px 14.5px !important;
    background-color: #ffffff !important;
}
.booking-confirm-dealer-popup-arrow {
    color: #fff !important;
    stroke-width: 2px !important;
    stroke: #6e6e6e !important;
    stroke-dasharray: 30px !important;
    stroke-dashoffset: -54px !important;
}

.phone-image {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    object-fit: contain;
}

.phone-icon {
    display: flex;
    object-fit: contain;
    width: 270px;
    height: 26px;
    margin: 24px 0px 0px 0px;
    font-family: $f1-font-regular;
    font-size: 20px;
    line-height: 1.5;
    color: $fds-color-primary;
}

.popup-dealer-address {
    width: 100%;
    margin: 23px 0 23px;
    font-family: $f1-font-regular;
    font-size: 16px;
    line-height: 1.63;
    color: #4d4d4d;
}

span.close-icon {
    width: 16px;
    height: 16px;
    margin: 0 0 34px 250px;
    object-fit: contain;
    cursor: pointer;
}
