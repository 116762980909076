@import '../../../../styles/main.scss';

.comment-container {
    width: 100%;
    .comments-section-title {
        width: 100%;
        height: auto;
        font-family: $f1-font-regular;
        font-size: 24px;
        line-height: 1;
        color: #00095b;
        margin: 40px 0 25px;
        font-stretch: normal;
        font-style: normal;
        text-transform: capitalize;
        overflow-wrap: break-word;
        @include mobile {
            margin: 35px 0 25px;
        }
    }
    .comments-body {
        .comments-header {
            width: 100%;
            margin: 0 0 24px;
            font-family: $f1-font-regular;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            color: $fm-text-grey;
            ul {
                list-style-position: outside;
                padding: 0 13px;
                li {
                    list-style-type: none;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.63;
                    color: #4d4d4d;
                    &::marker {
                        content: '- ';
                        padding: 0 5px;
                        margin: 20px;
                    }
                }
            }
        }
    }
    .comments-remaining-char {
        width: 100%;
        margin: 15px 0 24px;
        font-family: $f1-font-regular;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: #4d4d4d;
    }
    .comment-input textarea {
        width: 100%;
        display: block;
        box-sizing: border-box;
        border: 1px solid #6e6e6e;
        border-radius: 3px;
        resize: none;
        background-color: #fefefe;
        min-height: 50px;
        letter-spacing: normal;
        word-spacing: normal;
        font-size: 16px;
        font-family: $f1-font-regular;
        padding: 5px;
    }
    .comment-input {
        padding-top: 15px;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: #4d4d4d;

        .osb-error-icon {
            width: 20px;
            float: initial;
        }
    }
    .comment-empty-error {
        border: solid 1px #d62d14;
        background-color: #ffffff;
        box-shadow: none;
    }
    .empty-commments-error {
        font-family: $f1-font-regular;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        color: #d62d0a;
    }
}
