@import '../../../../../../styles/main.scss';

.light-dealer-step-wrapper {
    position: relative;
    picture {
        display: flex;
        @include mobile {
            position: absolute;
        }
        @include tablet-portrait {
            position: absolute;
        }
        img {
            width: 100%;
            height: 1200px;
            object-fit: cover;
            object-position: 70% 10%;
            @include mobile {
                object-fit: cover;
                object-position: 80% 10%;
                height: 210px;
            }
        }
    }
    .dealer-page-bck-img {
        padding: 50px 20px;
        width: 100%;
        min-height: 810px;
        position: absolute;
        @include mobile {
            position: relative;
        }
        @include tablet-portrait {
            position: relative;
        }
        @media only screen and (min-width: 768px) {
            background-size: cover;
            background-repeat: no-repeat;
        }

        @include mobile {
            background-image: none !important;
            padding: 0;
            min-height: 0;
        }
        @media only screen and (max-width: 1024px) and (min-width: 280px) {
            font-size: 25px;
        }

        .light-journey-title {
            font-family: $f1-font-light;
            font-size: 34px;
            line-height: 1.29;
            color: #ffffff;
            width: 70%;
            max-width: 100%;
            margin: 0 0 25px 70px;
            text-transform: uppercase;

            @include mobile {
                display: flex;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding: 20px;
                height: 210px;
                width: 100%;
                font-size: 20px;
                font-family: $f1-font-regular;
                line-height: 30px;
                margin: 0;
            }
        }
        .light-dealer-flow-container-wrapper {
            width: 608px;
            @media only screen and (max-width: 1200px) and (min-width: 768px) {
                width: 48%;
            }
            @include mobile {
                width: 100%;
            }
            .light-journey-title {
                font-family: $f1-font-light;
                font-size: 34px;
                line-height: 44px;
                color: #ffffff;
                margin: 0 0 25px 70px;
                text-transform: uppercase;
                word-break: break-word;
                @include mobile {
                    display: none;
                }
            }
            .light-dealer-flow-container {
                display: flex;
                flex-direction: column;
                background-color: #fff;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 41px;
                padding-bottom: 50px;
                margin-left: 70px;
                border-radius: 3px;
                height: auto;

                @include mobile {
                    padding: 20px;
                    margin: 0;
                }

                @media screen and (max-width: 1024px) and (min-width: 768px) {
                    top: 200px;
                }
                @media screen and (max-width: 1200px) {
                    padding: 30px;
                }
                @media screen and (max-width: 280px) {
                    padding: 18px;
                }

                .dealer-search-container {
                    padding-bottom: 80px;
                    @include mobile {
                        padding-bottom: 0;
                        padding-top: 5px;
                    }
                    @media screen and (max-width: 1024px) and (min-width: 768px) {
                        padding-bottom: 95px;
                    }
                    .auto-complete-container {
                        position: absolute;
                        width: 100%;
                        @include mobile {
                            position: relative;
                        }
                    }

                    .osb-light-dealer-search-container {
                        .osb-light-dealer-search-box-container {
                            padding-top: 0px !important;
                        }
                        .location-error-message {
                            position: relative;
                            top: 80px;
                            @include mobile {
                                position: relative;
                                top: 0;
                            }
                        }
                    }
                }

                .light-dealer-search-box {
                    width: 85%;
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                    }
                }

                .hr-line-above-retrieve {
                    border-bottom: 1px solid #6e6e6e;
                    margin: 27px 0 0;
                }
                .hr-line-below-retrieve {
                    border-bottom: 1px solid #6e6e6e;
                    margin: 38px 0 0;
                    @include mobile {
                        margin: 29px 0 0;
                    }
                }
            }
        }
    }
}

.ford-button-container {
    @media only screen and (max-width: 1095px) {
        grid-template-columns: 100% !important;
        justify-items: center !important;
    }
}
