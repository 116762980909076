@import '../../../../../styles/main.scss';

.osb-service-checkbox-wrapper,
.osb-new-service-listing {
    input[type='checkbox'] {
        appearance: none;
    }

    .radio-style {
        margin-right: 15px;
        margin-top: 0;
        color: $fm-skyview;
        accent-color: $fm-skyview;
        min-width: 24px;
        min-height: 24px;
    }
    .checkbox {
        margin-top: 19px;
        margin-right: pToR(19) !important;
        &:before {
            margin-right: 0px !important;
            display: inline-block;
            color: $fds-color-primary;
            width: pToR(17);
            height: pToR(17);
            background-color: #fff;
            border: 2px solid $fds-color-dk-gray;
            border-radius: 3px;
            content: '';
            box-sizing: border-box;
            text-align: center;
            padding: 0;
            font-size: 10px;
        }

        &:checked {
            &:before {
                content: '\2714' !important;
                border-color: $fm-breadcrumb-blue;
                color: $white;
                background-color: $fm-breadcrumb-blue;
            }
        }
    }

    .osb-check-box-spacing {
        margin-top: 0.75%;
        margin-bottom: 20px;

        .checkbox {
            margin-top: 4px;
        }

        .tooltip {
            top: -1px;
            line-height: normal;

            .tooltip__infoIcon {
                &.dark {
                    font-size: 25px;
                    -webkit-text-fill-color: $fm-breadcrumb-blue;
                    top: 0;
                    color: $fm-breadcrumb-blue;
                    background-color: $scard-light-grey;
                }
            }
        }
    }

    .checkbox-label {
        width: 197px;
        height: 26px;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: $dyf-color-darkgrey;
    }

    .otherServiceDesc-input {
        padding-top: 10px;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        color: #4d4d4d;
        @include mobile {
            padding-bottom: 10px;
        }
    }
    .osb-error-icon {
        width: 20px;
        float: initial;
    }
    .otherServiceDec-input-textarea {
        display: block;
        width: 300px;
        box-sizing: border-box;
        border: 1px solid #6e6e6e;
        border-radius: 3px;
        resize: both;
        background-color: #fefefe;
        height: 40px;
        letter-spacing: normal;
        word-spacing: normal;
        font-size: 16px;
        font-family: $f1-font-regular;
        padding: 5px;
        color: #4d4d4d;
        font-stretch: normal;
        font-style: normal;

        @include tablet-portrait {
            width: 100%;
            height: 76px;
        }
    }
    .otherServiceDec-empty-error {
        border: solid 1px #d62d14;
        background-color: #ffffff;
        box-shadow: none;
    }

    .otherServiceDec-status {
        height: 16px;
        color: #4d4d4d;
        font-family: $f1-font-regular;
        font-size: 12px;
        margin-top: 5px;
    }
    .service-list-panel-item {
        .osb-check-box-spacing {
            display: flex;

            .checkbox-content {
                width: 80%;
                display: flex;
                margin: 6px;
                .service-description {
                    .offer-label {
                        background-color: #008200;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        color: #ffffff;
                        width: fit-content;
                        margin-bottom: 10px;
                        padding: 2px 5px;
                    }
                    .service-disclaimers {
                        width: 513px;
                        height: 21px;
                        margin: 0px 77px 0px 10px;
                        font-family: $f1-font-regular;
                        font-size: 10px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.91;
                        color: #4d4d4d;
                    }
                }
            }

            .service-checkbox-price-info {
                display: grid;
                grid-template-columns: 70% 25%;
                .price-on-request {
                    padding-top: 7px;
                }
            }

            .price-details {
                width: 50%;
                white-space: nowrap;
                font-family: $f1-font-bold;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: #4d4d4d;
                margin-top: 5px;
                div {
                    float: right;
                    margin-right: 3px;
                }
                .strike-through {
                    color: #d62d14;
                    text-decoration: line-through;
                }
            }
        }
        .osb-check-box-spacing.selected-service {
            label,
            .checkbox-label {
                font-family: $f1-font-regular;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
            }
            .price-details {
                font-family: $f1-font-bold;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
            }
        }
    }
}

.floating-panel__wrapper {
    .floating-panel__content {
        @include tablet-portrait {
            height: auto !important;
            min-height: 103vh;
        }
    }
}
