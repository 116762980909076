@import '../../../../../styles/main.scss';
.modal-file-drop-content {
    background: #fff;
    border: 1px solid #949699;
    opacity: 1.4;
    width: 100%;
    height: 70%;
    background-color: #fff;
    margin-top: 50%;
    display: flex;
    align-items: center;

    .popup-body-content-light {
        width: 100%;
        height: 80%;
        border-radius: 3px;
        border: 1px dashed #4d4d4d;
        background-color: #fff;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 1%;
        margin-right: 1%;
        display: flex;
        align-items: center;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

        .body-content-text {
            text-align: center;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            color: #4d4d4d;
            margin: auto;
        }
    }

    .popup-body-content-light::-webkit-scrollbar {
        width: 3px;
    }

    .popup-body-content-light::-webkit-scrollbar-thumb {
        width: 3px;
        background: #fff;
    }
}
