@import '../../../../styles/main.scss';

.osb-datetime-container,
.pickup-service-timeslot-component {
    @include mobile {
        margin-top: -12px;
    }
    .osb-timeslot-picker-container {
        @include tablet-portrait {
            order: 7;
        }
        .main-head {
            font-family: $f1-font-regular;
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0px;
            text-align: left;
            color: #00095b;
            padding-left: 22px;
            margin-bottom: 20px;
            white-space: break-spaces;
            @include tablet-portrait {
                text-align: left;
                margin: 25px 0;
                font-size: 130%;
                padding-left: 0;
            }
            @include mobile {
                margin: 25px 0 25px 0;
                padding-left: 0;
                font-size: 20px;
                line-height: 24px;
            }
        }
        .time-range-main-head {
            font-family: $f1-font-regular;
            font-size: 24px;
            line-height: 1.63;
            color: #00095b;
            text-transform: capitalize;
            text-align: left;
            margin-bottom: 20px;
            white-space: break-spaces;
            @include tablet-portrait {
                text-align: left;
                margin: 25px 0;
                font-size: 130%;
            }
            @include mobile {
                margin: 30px 0 0 0;
                padding-left: 0;
                text-transform: uppercase;
                color: #4d4d4d;
            }
        }
        .timeslot-empty-label {
            padding-top: 25px;
            @include tablet-portrait {
                order: 6;
                padding-top: 15px;
            }
            @include mobile {
                display: none;
            }
        }

        .radio-toolbar {
            display: grid;
            grid-template-rows: repeat(6, minmax(45px, 45px));
            grid-gap: 0.2rem;
            grid-auto-flow: row;
            margin: 0 auto;
            height: auto;
            padding: 0px;
            position: relative;

            @media only screen and (min-width: 768px) and (max-width: 1200px) {
                grid-template-columns: repeat(3, 80px);
            }

            @media only screen and (min-width: 1201px) {
                grid-template-columns: repeat(4, 80px);
            }

            @include tablet-portrait {
                width: 100%;
                grid-template-columns: repeat(4, 4.21fr);
                grid-template-rows: auto; //repeat(6, minmax(70px, 70px));
            }

            @include mobile {
                padding-left: 0px;
                grid-template-columns: repeat(4, 1fr);
            }

            @include xs-mobile {
                padding-left: 10px;
                grid-template-columns: repeat(3, 0.32fr);
            }
            @media only screen and (max-width: 280px) {
                padding: 0;
            }

            span {
                @include mobile {
                    padding: 5px;
                }
                @include tablet-portrait {
                    padding: 5px;
                }
                width: 0;
            }
            input[type='radio'] {
                opacity: 0;
                position: fixed;
                width: 0;
                height: 0;
                pointer-events: none;
            }
            label {
                display: inline-block;
                padding: 13px 4px;
                font-size: 100%;
                border-radius: 3px;
                border: solid 1px $fm-text-grey;
                color: $fm-text-grey;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                height: 40px;
                width: 72px;
                text-align: center;
                @include tablet-portrait {
                    padding: 10px 4px;
                    font-size: 120%;
                }
            }
            label:hover {
                background: $fm-breadcrumb-blue;
                color: $fds-color-white;
                cursor: pointer;
            }

            .radio-custom:focus ~ label {
                border: 2px solid #444;
            }
            .radio-custom:checked ~ label {
                background: $fm-breadcrumb-blue;
                color: $fds-color-white;
                box-shadow: none;
                border: solid 1px $fm-breadcrumb-blue;
            }
        }
        .dropdown-items-panel::-webkit-scrollbar {
            width: 10px;
            background-color: #f2f2f2;
            border: 1px solid #fff;
            margin-right: 20px;
        }

        .dropdown-items-panel::-webkit-scrollbar-thumb {
            width: 10px;
            background-color: #6e6e6e;
            border: 1px solid #fff;
            margin-right: 20px;
        }
    }
    .timerange-picker {
        @include mobile {
            margin-top: 20px;
        }
        .input-label {
            padding: 0;
            font-size: 14px;
            @include mobile {
                margin-top: 10px;
            }
        }
        .dropdown-container-wrapper {
            padding-top: 0px;
            @include tablet-portrait {
                width: 100%;
                padding-top: 12px;
            }

            .dropdown {
                .dropdown-button {
                    .dropdown-current-item {
                        font-size: 14px;
                    }
                }
                .dropdown-items-panel {
                    max-height: 270px;
                    .dropdown-item {
                        font-size: 16px;
                        &.selected-item {
                            background-color: $light-sky-blue;
                            color: $fds-color-primary;
                            font-weight: normal;
                        }
                    }
                }
            }

            .osb-dropdown-input {
                width: 100%;
                min-width: auto;
            }
        }
    }
}
