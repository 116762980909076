@import '../../../../../../styles/main.scss';
.osb-dealer-capabilities-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .dealer-capabilities-row {
        display: flex;
        align-items: center;
        margin: 3px 0;
        .dealer-capabilities-img-col {
            display: flex;
            justify-content: center;
            align-items: center;
            .dealer-capability-icon {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                @include mobile {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        .dealer-capabilities-text-col {
            .dealer-capability-text {
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
