@import './light-dealer-filter-options-ford.scss';

.brand-lincoln {
    .light-dealer-filter-options-container {
        .filters-header-container {
            .filters-header-reset {
                .filters-header {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
                .filters-reset {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
            }
        }
        .accordion-filter-container {
            .accordion-title {
                color: #324047;
                font-family: $proxima-nova-regular;
                background-color: #e0e0e0;
            }
        }
        .services-list {
            .osb-check-box-spacing {
                padding: 10px 0 10px 0;
                margin-top: 0;
                margin-bottom: 0;
                .checkbox {
                    &:before {
                        width: 20px;
                        height: 20px;
                        background-color: #fff;
                        border-color: #324047;
                        border-radius: 0;
                    }
                    &:checked {
                        &:before {
                            content: '\2714';
                            width: 20px;
                            height: 20px;
                            font-size: 16px;
                            border-color: #f26147;
                            background-color: #f26147;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0;
                        }
                    }
                }
                .tooltip .tooltip__infoIcon {
                    &.dark {
                        -webkit-text-fill-color: #324047;
                    }
                }
            }
        }
    }
    .floating-panel__wrapper,
    .floating-panel__pointer {
        z-index: 9999;
    }
}
