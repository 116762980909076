@import './osb-file-uploader-ford.scss';

.brand-lincoln {
    .osb-file-uploader-container {
        .osb-fu-main-title-container {
            .osb-fu-main-title {
                color: #324047;
                font-family: $proxima-nova-regular;
            }
            .tooltip .tooltip__infoIcon {
                &.dark {
                    -webkit-text-fill-color: #324047;
                }
            }
        }
        .osb-drag-drop-container {
            .osb-drag-drop-title {
                color: #324047;
                font-family: $proxima-nova-regular;
            }

            .osb-drag-drop-panel {
                border: 1px dashed #324047;

                div {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                    .osb-fu-upload-link {
                        color: #324047;
                        font-family: $proxima-nova-bold;
                    }
                }
            }
            .osb-drag-drop-valid-file-label {
                color: #324047;
                font-family: $proxima-nova-regular;
            }
        }
        .osb-fu-status-grid-container {
            .osb-fu-selected-files {
                .osb-fu-selected-file-name {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
                .osb-fu-selected-file-remove {
                    color: #324047;
                    font-family: $proxima-nova-bold;
                }

                .osb-fu-selected-file-confirm-remove {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }

                .osb-fu-selected-file-view {
                    color: #324047;
                    font-family: $proxima-nova-bold;
                }

                .osb-fu-selected-file-remove-decision {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
            }
            .osb-fu-rejected-files {
                .osb-fu-rejected-file-name {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
                .osb-fu-rejected-message {
                    font-family: $proxima-nova-regular;
                    color: #b00020;
                }
            }
        }
    }
}
