@import '../../../../../styles/main.scss';

.osb-video-container {
    margin: auto;
    display: block;
    width: 98%;
    max-width: 980px;
    height: 100%;
    border-radius: 3px;
    .video-js {
        height: 86% !important;
        width: 100% !important;

        video {
            object-fit: fill !important;
        }
    }

    .vjs-poster {
        background-size: fill;
        position: relative;
    }

    .vjs-big-play-button {
        background-color: transparent !important;
        background-image: url('../../../../../assets/play.png');
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 0;
        padding: 2px;
        width: 9rem;
        height: 8rem;
        .vjs-icon-placeholder {
            display: none;
        }
    }

    .discover-section {
        margin: 0 !important;
    }
}
