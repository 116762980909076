@import './light-datetime-ford.scss';

.brand-lincoln {
    .datetime-step-view-container {
        .osb-light-journey-datetime-page {
            .osb-datetime-container {
                .datetime-title {
                    font-family: $proxima-nova-regular;
                    color: #000;
                    letter-spacing: 1px;
                }

                .osb-calendar-container-panel {
                    .calendar-component {
                        @include tablet-portrait {
                            .input-field {
                                border: none;
                                border-bottom: solid 1px #6e6e6e;
                                background-color: #ecedee;
                                font-family: $proxima-nova-regular;
                                &:focus {
                                    box-shadow: $outer-drop-shadow;
                                    outline: none;
                                    border-bottom-color: $brand-border;
                                }
                            }
                        }

                        .cal-main-head {
                            font-family: $proxima-nova-regular;
                            color: #000;
                        }
                        .selected-date {
                            font-family: $proxima-nova-regular;
                            color: $brand-secondary;
                        }
                    }
                    .main-head {
                        font-family: $proxima-nova-regular;
                        color: #000;
                    }
                }
                .footer-text {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .call-dealer-link {
                    color: #4f4c43;
                    font-family: $proxima-nova-regular;
                }
            }
        }
        .dealer-call-cta-popup-content {
            color: #000;
            font-family: $proxima-nova-regular;
        }
    }
}
