@import '../../../../../../styles/main.scss';

.osb-home-retrieve-booking-container {
    .osb-home-retrieve-booking-header {
        font-family: $f1-font-regular;
        font-size: 24px;
        color: #00095b;
        text-transform: uppercase;
        text-align: left;
        margin-top: 25px;
        line-height: 28px;
        @include mobile {
            margin-top: 20px;
            line-height: 1.7;
            font-size: 20px;
        }
    }
    .osb-home-retrieve-booking-copy-text {
        width: 100%;
        margin-top: 30px;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
        color: #4d4d4d;
        @include mobile {
            margin-top: 20px;
        }
    }
    .osb-home-retrieve-booking-button-container {
        margin-top: 39px;
        .button-label {
            font-family: $f1-font-regular;
            font-size: 18px;
            line-height: 24px;
        }
        @media only screen and (max-width: 1024px) {
            margin-top: 29px;
            display: flex;
            justify-content: center;
        }
    }
}
