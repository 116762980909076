@import '../../../../../../styles/main.scss';

.osb-light-dealer-search-container {
    display: flex;
    flex-direction: column;
    .dealer-error-message {
        color: #d62d14;
        font-family: $f1-font-light;
        font-size: 16px;
        line-height: 1.313rem;
        white-space: break-spaces;
        text-align: start;
    }
    .light-first-heading {
        font-family: $f1-font-regular;
        font-size: 24px;
        line-height: 28px;
        color: #00095b;
        text-transform: uppercase;

        @include mobile {
            line-height: 30px;
            font-size: 20px;
        }

        .select-a-dealer-header-container {
            width: 93%;
            display: flex;
            justify-content: space-between;
        }
    }
    .osb-light-dealer-search-box-container {
        @include desktop {
            padding-top: 13px;
        }
        .serach-type-label {
            font-size: 11px;
            font-family: $f1-font-regular;
            color: #4d4d4d;
            margin: 20px 0;
            margin-top: 23px;
            @include mobile {
                margin: 27px 0;
            }
        }
        .radio-buttons {
            display: flex;
            margin-top: -9px;
            @include mobile {
                display: block;
                margin-top: -23px;
            }
            .radio-button-container {
                padding: 0;
                border: none;
                font-size: 12px;
                font-family: $f1-font-regular;
                max-width: 100% !important;
                .radio-button-input {
                    margin: 0;
                    color: $fm-skyview;
                    accent-color: $fm-skyview;
                }
                @include mobile {
                    margin-bottom: 15px;
                }
                .radio-button-label {
                    font-size: 16px;
                    font-family: $f1-font-regular;
                    margin: 0 10px;
                    @include mobile {
                        font-size: 16px;
                    }
                }
            }
        }
        .light-dealer-search-box {
            margin-top: 15px;
            position: relative;
            .autocomplete-box {
                width: 80%;
            }
            @include mobile {
                margin-top: 20px;
            }
            .dropdown-button {
                width: 100% !important;
                height: 55px !important;
                background-color: #ffffff !important;
            }

            .dropdown {
                margin: -12px 0 0 30px;
            }

            .autoCompleteText button {
                width: 39px;
                @include mobile {
                    width: 30px;
                }
                @include tablet {
                    width: 25px;
                }
                @include xs-mobile {
                    width: 30px;
                }
                @media only screen and (max-width: 320px) and (min-width: 280px) {
                    width: 28px;
                }
            }

            #selectLocation {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 24px;
                color: #00095b;
                padding: 12px 0 12px 15px;
            }
        }

        .location-error-message {
            top: -1.8rem;
            color: rgba(214, 45, 20, 1);
            font-size: 0.75rem;
            line-height: 1.313rem;
            @include mobile {
                margin-bottom: -27px;
            }
        }
    }
}
