@import './dealer-search-ford.scss';

.brand-lincoln {
    .osb-light-dealer-search-container {
        .light-first-heading {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            @include mobile {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
        }
        .dealer-error-message {
            color: $brand-border;
            font-family: $proxima-nova-light;
        }
        .light-dealer-search-box {
            #selectLocation {
                font-family: $proxima-nova-regular;
                letter-spacing: 1px;
                color: $brand-secondary;
                background: #ecedee;
            }
        }
        .location-error-message {
            font-family: $proxima-nova-regular;
            color: #b00020;
        }
    }
}
