@import '../../../../../../styles/main.scss';
.osb-dealer-details-mobile-list {
    // display: flex;
    padding-top: 30px;
    background-color: $fds-color-white;
    .list-actions {
        position: relative;
        top: 1%;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 10px 0 45px 0;
        &.fixed {
            position: fixed;
            top: 210px;
        }
        .filter-link,
        .list-link {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $fds-color-white;
            border: 1px solid $fm-breadcrumb-blue;
            color: $fm-breadcrumb-blue;
            border-radius: 20px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 14px;
            margin-bottom: 30px;
            width: 35%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .filters-text,
            .list-text {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 14px;
                font-weight: 700;
                margin: auto;
            }
        }
    }
    .dealer-container {
        display: flex;
        padding: 20px 20px 20px 20px;
        width: 100%;
        position: relative;
        &.preferred-dealer-container {
            background-color: $fds-color-white;
            border: 1px solid $fds-color-primary;
            border-radius: 10px;
            padding: 20px;
            width: 80%;
            margin: 39px auto 0;
        }
        .preferred-dealer-label {
            position: absolute;
            top: -26px;
            background-color: $fds-color-primary;
            font-family: $f1-font-regular;
            color: $fds-color-white;
            font-size: 11px;
            line-height: 17px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 5px 10px;
        }
        .dealer-number {
            height: 26px;
            width: 5%;
            color: $fds-color-primary;
            font-family: $f1-font-medium;
            font-size: 32px;
            line-height: 26px;
            text-align: center;

            margin-right: 20px;
            margin-left: 0px;
            margin-top: 6px;
        }
        .dealer-info {
            width: 95%;
            .dealer-name {
                color: $fds-color-primary;
                font-family: $f1-font-bold;
                font-size: 16px;
                line-height: 26px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .dealer-address {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 21px;
            }
            .dealer-distance-label {
                padding-top: 5px;
                padding-bottom: 10px;
                color: $fds-color-dk-gray;
                .distance-label-text {
                    margin-right: 5px;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 21px;
                }
                .distance-value {
                    width: 60px;
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 21px;
                }
            }
            .dealer-earliest-available-slot {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-family: $f1-font-regular;
                background-color: $lightgrey;
                color: $fm-text-grey;
                font-size: 11px;
                line-height: 21px;
                text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                padding: 3px 10px;
                border-radius: 2px;
                .earliest-slot-label-text {
                    margin-right: 5px;
                }
            }
            .dealer-list-actions {
                margin-top: 20px;
                display: flex;
                .details-link {
                    margin-top: 7px;
                    margin-left: 15px;
                    font-size: 14px;
                }
            }
        }
    }
    .separator {
        margin: 20px;
        border-bottom: 1px solid $fds-color-md-gray;
        height: 1px;
        width: 90%;
    }
    .view-more-container-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        .view-more-button-mobile {
            display: flex;
            align-items: center;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 20px;
            .view-more-text-mobile {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 24px;
                padding-right: 15px;
            }
            .fds-chevron-wrap {
                .fds-chevron {
                    color: $fm-breadcrumb-blue;
                }
            }
        }
    }
}
