@import '../../../../../styles/main.scss';
.osb-light-booking-details-section {
    grid-column: 2;
    width: 100%;
    border-radius: 16px;
    background-color: $scard-light-grey;
    @include mobile {
        order: 1;
        padding: 5%;
        background-color: $fds-color-white;
    }

    .osb-light-booking-details-header {
        width: 100%;
        margin-top: 20px;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 1.63;
        text-align: center;
        color: #00095b;
        @include mobile {
            margin-top: 0;
            margin-bottom: 30px;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            border-bottom: 1px solid #b2b2b2;
            padding-bottom: 30px;
        }
    }

    .osb-light-booking-details-container {
        margin: 15px 35px 35px 35px;
        @include mobile {
            margin: 0;
        }
        .service-section,
        .vehicle-section {
            .service-title,
            .vehicle-title {
                margin: 15px 0 0 0;
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .service-sub-title {
                color: #00095b;
                font-size: 16px;
                line-height: 1.63;
                font-family: $f1-font-regular;
                margin-top: 10px;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
            }
            .services-list {
                width: 100%;
                margin-top: 14px;
            }
            .light-recall-service-desc {
                font-size: 10px;
            }
            .services-list::-webkit-scrollbar {
                width: 2px;
            }

            .services-list::-webkit-scrollbar-thumb {
                width: 2px;
                background-color: #6e6e6e;
            }
            .service-name {
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
                .pick-up-details {
                    padding-top: 10px;
                    .selected-pickup-date {
                        margin-top: 10px;
                    }
                    .selected-pickup-time {
                        margin-bottom: 10px;
                    }
                    .customer-address {
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #00095b;
                        margin-bottom: 10px;
                    }
                }
                .sl-comments {
                    margin: 22px 0 10px 0;
                }
            }
            .vehicle-name {
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                margin-top: 14px;
            }
            .image-upload-count {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                margin-top: 10px;
            }
        }
        .service-location-container {
            .service-location-title {
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                margin: 15px 0 14px 0;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .service-location-details {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                margin-bottom: 10px;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
                .sl-comments {
                    margin: 22px 0 10px 0;
                }
            }
        }
        .where-section {
            .where-title {
                margin: 15px 0 12px 0;
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .dealer-name,
            .dealer-address {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
            }
        }
        .when-section {
            .when-title {
                margin: 15px 0 12px 0;
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .date-time {
                font-family: $f1-font-regular;
                width: 100%;
                margin: 12px 0 24px;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
            }
        }
        .booking-number-section {
            .booking-number-title {
                margin: 15px 0 12px 0;
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .booking-number {
                font-family: $f1-font-regular;
                margin: 12px 0 24px;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
            }
        }
        .access-code-section {
            .access-code-title {
                margin: 15px 0 12px 0;
                font-family: $f1-font-medium;
                font-size: 20px;
                line-height: 1.63;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .access-code {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                }
            }
            .hr-line-style {
                @include mobile {
                    display: none;
                }
            }
        }

        .links-section {
            @media print {
                display: none;
            }

            .print-booking-details {
                width: 190px;
                height: 26px;
                margin: 24px 0 23px 0;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.88;
                color: #00095b;
                @include tablet-portrait {
                    display: none;
                }
            }
        }

        .link-style {
            color: #042361;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 20px;
        }

        .hr-line-style {
            content: ' ';
            display: block;
            border-bottom: 1px solid $fds-color-lt-gray-b;
            margin: 30px 0 0;
        }

        .hr-line {
            content: ' ';
            display: block;
            border-bottom: 1px solid #6e6e6e;
            margin: 0;
        }
    }
}
