@import '../../../../../../styles/main.scss';
.dealer-info-box-container {
    max-height: 330px;
    overflow-y: auto;
    @media only screen and (max-width: 767px) {
        //mobile
        max-height: 310px;
        width: 311px;
    }
    /* Customize the scrollbar */
    &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: $fds-color-lt-gray;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $quicklink-textactive;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $quicklink-textactive;
    }
    .dealer-info-box {
        display: flex;
        justify-content: space-between;
        @media only screen and (min-width: 768px) {
            align-items: center;
        }
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .dealer-info {
            @media only screen and (min-width: 768px) {
                width: 65%;
            }
            @media only screen and (max-width: 767px) {
                width: 95%;
            }
            flex-grow: 1;
            .dealer-name {
                color: $fds-color-primary;
                font-family: $f1-font-bold;
                font-size: 16px;
                line-height: 26px;
                text-transform: capitalize;
            }

            .dealer-address {
                padding-top: 10px;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 21px;
            }
            .dealer-distance-label {
                padding-top: 5px;
                padding-bottom: 10px;
                color: $fm-text-grey;
                .distance-label-text {
                    margin-right: 5px;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 21px;
                }
                .distance-value {
                    width: 60px;
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 21px;
                }
            }

            .dealer-earliest-available-slot {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $lightgrey;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                font-size: 11px;
                line-height: 21px;
                text-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
                padding: 0 10px;
                border-radius: 2px;
                .earliest-slot-label-text {
                    margin-right: 5px;
                }
            }

            .dealer-services-container {
                padding-top: 15px;
                padding-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                max-width: 255px;
                justify-content: space-between;
                .dealer-service-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    margin-right: 20px;
                    .dealer-service-image {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                    .dealer-service-text {
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        line-height: 21px;
                    }
                }
            }
            .dealer-rating-container {
                display: flex;
                align-items: center;
                .dealer-star-rating {
                    font-size: 24px;
                    color: gold;
                }
                .dealer-number-rating {
                    font-size: 18px;
                    margin-left: 10px;
                }
                .dealer-review-rating {
                    font-size: 14px;
                    color: $grabber-blue;
                    text-decoration: none;
                    margin-left: 10px;
                }
            }
        }
        .dealer-actions {
            display: flex;
            @media only screen and (max-width: 767px) {
                width: 100%;
                align-items: flex-end;
                margin-top: 15px;
            }
            @media only screen and (min-width: 768px) {
                width: 35%;
                flex-direction: column;
                justify-content: space-between;
            }

            .select-dealer-button {
                @media only screen and (max-width: 767px) {
                    display: flex;
                    align-items: center;
                }
                justify-content: center;
                width: 115px;
                font-family: $f1-font-bold;
                font-size: 14px;
                line-height: 24px;
                margin: auto;
            }

            .details-link {
                align-self: flex-start;
                @media only screen and (max-width: 767px) {
                    padding-left: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                margin: auto;
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 18px;
                line-height: 18px;
                .dealer-details-button {
                    padding: 20px 0;
                    white-space: break-spaces;
                    display: block;
                    text-align: center;
                    ul {
                        font-size: 14px;
                        grid-template-columns: 80% 20%;
                        display: grid;
                    }
                }
            }
        }
    }
}
