@import '../../../../../../styles/main.scss';
.osb-light-dealer-search-section-wrapper {
    min-height: 208px;
    @include tablet-portrait {
        min-height: 220px;
    }
    .row-display {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .column-display {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .back-button-column {
        padding-top: 24px;
        padding-left: 24px;
    }
    .select-a-dealer-title-column {
        font-family: $f1-font-light;
        font-size: 34px;
        color: #00095b;
        padding-left: 48px;
        @include desktop {
            padding-top: 6px;
        }
    }
    .dealer-search-column {
        height: 100px;
        padding-left: 15px;
        .auto-complete-container {
            width: 100%;
        }
        .dealer-search-section {
            .osb-light-dealer-search-container {
                flex: 2.5;
                .osb-light-dealer-search-box-container {
                    .light-dealer-search-box {
                        display: inline-flex;
                        width: 100%;
                        .auto-complete-container {
                            flex: 2;
                        }
                        .use-location-box {
                            flex: 1;
                            height: 30px;
                            margin-top: 34px;

                            .location-link-container {
                                flex-grow: 2;
                                margin-bottom: 10px;
                                margin-left: 20px;
                            }
                        }
                        .location-error {
                            margin-bottom: 33px !important;
                        }
                        .autocomplete-box {
                            width: 55%;
                        }
                    }
                }
            }
        }
    }
    .search-a-dealer-mobile-container {
        padding-top: 37px;
        padding-left: 27px;
        width: 85%;
        .search-a-dealer-mobile-title {
            font-family: $f1-font-regular;
            font-size: 20px;
            line-height: 30px;
            color: #00095b;
        }
        .search-a-dealer-mobile {
            width: 100%;
            .osb-use-location {
                margin-top: 20px;
            }
        }
    }
}

.light-journey-dealer-details-container {
    display: grid;
    grid-template-columns: 48% 52%;
    grid-template-rows: 9% 21% 72%;
    grid-row-gap: 1%;
    grid-column-gap: 0%;
    @include tablet-portrait {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .osb-secondary-button {
        padding-left: 20px;
        @include tablet-portrait {
            padding: 20px;
        }
    }
    .dealer-filter-banner-container {
        align-items: center;
        color: #102b4e;
        display: flex;
        font-family: $f1-font-light;
        font-size: 18px;
        height: 60px;
        justify-content: center;
        line-height: 13px;
        text-align: center;
        width: 100%;
        background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
            0 20px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .select-a-dealer {
        @media only screen and (width: 768px) {
            margin: 20px 20px 120px;
        }
        margin: 20px 20px;
        display: grid;
        grid-template-columns: 65% 35%;
        grid-template-rows: 40% 60%;
        @include tablet-portrait {
            grid-template-columns: 100%;
            grid-template-rows: 60% 40%;
            order: 2;
        }
        .auto-complete-container {
            position: absolute;
            width: 100%;
            @include mobile {
                position: relative;
            }
        }

        .osb-light-dealer-search-container {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            @include tablet-portrait {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }
            .location-error-message {
                position: relative;
                top: 80px;
                @include mobile {
                    position: relative;
                    top: 0;
                }
            }
            .osb-light-dealer-search-box-container {
                .light-dealer-search-box {
                    width: 100%;
                    @include tablet-portrait {
                        width: 100%;
                    }
                    .auto-complete-container .autoCompleteText {
                        width: 365px;
                    }
                    .auto-complete-container {
                        width: 82%;
                        @include tablet-portrait {
                            width: 100%;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 1024px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .osb-use-location {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            padding-top: 30px;
            width: fit-content;
            @media only screen and (min-width: 1200px) {
                display: flex;
                justify-content: flex-end;
            }
            @include tablet-portrait {
                margin: 25px 0 0 0;
                padding: 0;
                width: 100%;
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
            @media only screen and (width: 768px) {
                position: absolute;
                top: 250px;
            }
            button p {
                @media only screen and (min-width: 981px) and (max-width: 1024px) {
                    font-size: 13px;
                }
                @media only screen and (min-width: 768px) and (max-width: 980px) {
                    font-size: 12px;
                }
            }
            button {
                justify-content: left;
                @media only screen and (min-width: 1200px) {
                    justify-content: flex-end;
                }
                padding: 0;
                @include tablet-portrait {
                    justify-content: center;
                    margin-top: 10px;
                }
            }
        }
        .filters {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            display: flex;
            justify-content: flex-end;
            @media only screen and (min-width: 769px) and (max-width: 930px) {
                padding-left: 10px;
            }
        }
    }
    .dealer-list-container-without-filter {
        grid-row: 1 / 4 !important;
    }
    .dealer-list-container {
        grid-column: 2 / 3;
        grid-row: 2 / 4;
        max-height: 500px;
        overflow-y: auto;
        padding-left: 5%;
        @include mobile {
            padding-left: 0%;
        }
        @include tablet-portrait {
            max-height: none;
            overflow-y: hidden;
            order: 4;
            margin-top: 0;
            padding-left: 0%;
        }
        .dealer-items-container {
            display: grid;
            grid-template-columns: 8% 57% 35%;
            margin: 0 0 0 0;
            padding-top: 40px;
            @include tablet-portrait {
                grid-template-columns: 10% 90%;
                padding: 0 10% 5% 10%;
                margin: 25px 0 0 0;
            }
            .sequence-number {
                font-family: $f1-font-regular;
                font-size: 48px;
                color: #00095b;
                text-align: center;
                line-height: 1.21;
                @include tablet-portrait {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                    font-size: 34px;
                    line-height: 1.29;
                }
                @media only screen and (max-width: 320px) {
                    font-size: 28px;
                }
            }
            .dealer-info {
                @include tablet-portrait {
                    grid-column: 2 / 2;
                    grid-row: 1 / 2;
                    margin-left: 8px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin-left: 15px;
                }
                .dealer-name {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    color: #00095b;
                    text-transform: uppercase;
                    padding-top: 6px;
                    @include tablet-portrait {
                        padding-top: 3px;
                    }
                }
                .dealer-address {
                    display: flex;
                    margin: 8px 0 0 0;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    color: #4d4d4d;
                    @include tablet-portrait {
                        font-size: 12px;
                        line-height: 1.75;
                    }
                }
                .distance-miles {
                    display: flex;
                    margin: 16px 0 0 0;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 1;
                    color: #4d4d4d;
                    @include tablet-portrait {
                        font-size: 11px;
                        line-height: 1.91;
                        margin: 8px 0 0 0;
                    }
                    .miles-label {
                        margin-left: 3px;
                    }
                }
                .dealer-vehicle-services {
                    margin: 25px auto;
                    .service-name {
                        font-size: 12px;
                        font-family: $f1-font-regular;
                        color: #4d4d4d;
                        padding: 5px 0;
                        display: flex;
                        img {
                            height: 16px;
                            width: 16px;
                            margin-right: 10px;
                        }
                    }
                }
                .special-service-provided {
                    margin-top: 18px;
                    @include tablet-portrait {
                        margin: 20px 0;
                    }
                    @include xs-mobile {
                        font-size: 13px;
                    }
                    .special-service-provided-title {
                        font-size: 12px;
                        color: #4d4d4d;
                        font-family: $f1-font-regular;
                        margin-bottom: 15px;
                    }
                    .service-provided-list {
                        padding: 0 25px;
                        font-family: $f1-font-regular;
                        font-size: 12px;
                        color: #4d4d4d;
                        .service-provided-item {
                            list-style-type: square;
                            margin-bottom: 10px;
                        }
                    }
                }
                .earliest-available-slot {
                    width: fit-content;
                    margin: 20px 0 0 0;
                    font-family: $f1-font-regular;
                    border-radius: 3px;
                    text-align: left;
                    background-color: #1700f4;
                    font-size: 11px;
                    padding: 5px 10px 5px 5px;
                    color: #fff;
                    @include tablet-portrait {
                        margin: 20px 0 0 0;
                    }
                    @media only screen and (max-width: 320px) {
                        font-size: 9px;
                        padding: 0;
                    }
                }
            }
            .select-button-container {
                margin-top: 20px;
                margin-left: 25px;
                @include tablet-portrait {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 0px;
                    margin-left: 0px;
                }

                .primary-button {
                    max-width: 172px;
                    justify-content: center;
                    padding: 0px 25px;
                    min-height: 50px;
                    @include tablet-portrait {
                        max-width: 240px;
                    }
                    .button-label {
                        font-size: 18px;
                        font-family: $f1-font-regular;
                        line-height: 1.25;
                        color: #fff;
                        white-space: break-spaces;
                        padding: 12px 0px;
                    }
                }

                @include tablet-portrait {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                    margin: 30px 0;
                }
                .more-info-button-section {
                    margin: 32px 0 0 12px;
                    .osb-secondary-button {
                        padding: 0;
                        display: block;
                        white-space: break-spaces;
                        word-wrap: break-word;
                        .fds-chevron-wrap {
                            margin-left: 10px;
                        }
                        @include tablet-portrait {
                            text-align: center;
                        }
                    }
                    @include tablet-portrait {
                        margin-top: 17px;
                    }
                }
            }
            .hr-line-style {
                grid-column: 1 / 4;
                border-bottom: 1px solid #6e6e6e;
                margin: 28px 0 0 0;
                @include tablet-portrait {
                    margin: 0;
                }
            }
        }
    }
    .dealer-list-container::-webkit-scrollbar {
        width: 5px;
        height: 414px;
        background-color: #f2f2f2;
    }

    .dealer-list-container::-webkit-scrollbar-thumb {
        width: 5px;
        height: 314px;
        background-color: #6e6e6e;
    }
    .lj-back-button {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        margin: 15px 0 0 0;
        color: #00095b;
        cursor: pointer;
    }
    .lj-view-more-button-container {
        display: flex;
        justify-content: center;
        margin: 10px 36px 0 0;
        .lj-view-more {
            margin-top: 15px;
            color: #00095b;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .fds-chevron-wrap {
                display: block;
                margin-left: 5px;
            }
        }
        @include tablet-portrait {
            margin: 20px 0 20px 0;
        }
    }
    .osb-map-container {
        grid-column: 1 / 2;
        grid-row: 1 / 4;
        min-height: 450px;
        padding-bottom: 22px;
        @include mobile {
            padding-bottom: 0px;
        }
        @include tablet-portrait {
            margin-top: 33px;
            height: 300px;
            order: 3;
            padding-bottom: 0px;
        }
    }
    .dealer-filter-options-container {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
        z-index: 99;
        background: white;
        height: 96%;
    }
}
.filters {
    cursor: pointer;
    display: flex;
    @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 59px;
        width: 100%;
        cursor: pointer;
        z-index: 2004;
        background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
        background-color: #fff;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
            0 20px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .filter-icon {
        width: 20px;
        height: 19px;
        object-fit: contain;
        margin: 0;
        @media only screen and (max-width: 768px) {
            width: 24px;
            height: 23px;
        }
    }
    .filter-label {
        text-transform: capitalize;
        font-size: 18px;
        font-family: $f1-font-regular;
        color: #00095b;
        line-height: 0.72;
        margin: 5px 0 0 8px;
    }
}
.top-sticky-filters {
    cursor: pointer;
    display: flex;
    @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 59px;
        width: 100%;
        position: fixed;
        cursor: pointer;
        z-index: 2004;
        background: linear-gradient(180deg, #ffffff 0%, #d8d8d8 100%);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
            0 20px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .filter-icon {
        width: 20px;
        height: 19px;
        object-fit: contain;
        margin: 0;
        @media only screen and (max-width: 768px) {
            width: 24px;
            height: 23px;
        }
    }
    .filter-label {
        text-transform: capitalize;
        font-size: 18px;
        font-family: $f1-font-regular;
        color: #00095b;
        line-height: 0.72;
        margin: 5px 0 0 8px;
    }
}
