@import '../../../../../styles/main.scss';
.osb-pickup-service-calendar-section-container {
    padding: 40px 67px 0px 50px;

    @include mobile {
        padding: 29px 26px 0px 26px;
    }
    .pickup-service-calendar-title {
        font-family: $f1-font-light;
        text-transform: uppercase;
        font-size: 34px;
        line-height: 44px;
        text-align: left;
        color: #00095b;
        margin: 0px;
        @include mobile {
            font-size: 20px;
            line-height: 44px;
            font-family: $f1-font-regular;
        }
    }
    .pickup-service-calendar-appointment-container {
        margin-top: 49px;
        border: 1px solid #6a6a6a;
        border-radius: 4px;
        width: 98%;
        min-height: 87px;
        padding: 13px;
        @include mobile {
            margin-top: 11px;
            width: 100%;
        }
        .pickup-service-calendar-appointment-header {
            display: grid;
            grid-template-columns: 80% 20%;
            grid-column-gap: 2%;
        }

        .pickup-service-calendar-appointment-title {
            display: inline-block;
            font-family: $f1-font-medium;
            font-size: 20px;
            line-height: 24px;
            text-align: left;
            color: #00095b;
        }
        .pickup-service-calendar-appointment-edit-link {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: right;
            color: #00095b;
            text-decoration: underline;
            cursor: pointer;
            float: right;
            margin-right: 20px;
            @include mobile {
                margin-right: 17px;
            }
        }
        .pickup-service-calendar-appointment-date-time {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: left;
            color: #4d4d4d;
            margin-top: 10px;
        }
    }
    .pickup-service-calendar-time-and-date-title {
        margin-top: 52px;
        font-family: $f1-font-regular;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: #00095b;
        @include mobile {
            font-size: 20px;
            margin-top: 63px;
        }
    }
    .pickup-service-calendar-time-and-date-text {
        margin-top: 16px;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        color: #4d4d4d;
        @include mobile {
            margin-top: 14px;
        }
    }
    .pickup-date-text-container {
        min-height: 364px;
        .pickup-input-text {
            width: 280px;
            height: 46px;
            border-width: thin;
            caret-color: rgba(0, 0, 0, 0);
            font-size: 16px;
            color: #00095b;
            font-family: $f1-font-regular;
            padding-left: 16px;
            @include tablet-portrait {
                width: 100%;
            }
            @include mobile {
                margin-top: 11px;
            }
        }
        .pickup-input-text:focus {
            outline: none;
        }
        .input-textbox-label {
            margin-bottom: 4px;
            font-family: $f1-font-regular;
            font-size: 11px;
            text-align: left;
            line-height: 16px;
            color: #4d4d4d;
            margin-top: 20px;
        }
        .timeslot-picker,
        .radio-toolbar {
            grid-template-rows: repeat(3, minmax(50px, 45px));
            grid-gap: 0.9rem;
            margin-bottom: 70px;
            @include mobile {
                grid-template-rows: repeat(3, minmax(40px, 45px));
                grid-gap: 1.1rem;
            }
        }
    }
    .pickup-service-timeslot-title {
        margin-top: 72px;
        margin-bottom: 23px;
        font-family: $f1-font-regular;
        font-size: 24px;
        text-align: left;
        line-height: 28px;
        color: #00095b;
        @include mobile {
            font-size: 20px;
            margin-top: 62px;
        }
    }
}
