@import './osb-use-location-ford.scss';

.brand-lincoln {
    .osb-use-location {
        button {
            div {
                color: $brand-secondary;
                font-family: $proxima-nova-semi-bold;
            }
        }
    }
}
