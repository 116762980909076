@import './light-dealer-details-ford.scss';

.brand-lincoln {
    .light-journey-dealer-details-container {
        .dealer-list-container {
            .dealer-items-container {
                @include tablet-portrait {
                    grid-template-rows: 65% 20%;
                }
                .sequence-number {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
                .dealer-info {
                    .dealer-name,
                    .distance-miles,
                    .dealer-address,
                    .special-service-provided {
                        color: #324047;
                        font-family: $proxima-nova-regular;
                        .service-provided-list {
                            .service-provided-item {
                                list-style-type: disc;
                            }
                        }
                    }
                    .earliest-available-slot {
                        background-color: #324047;
                        font-family: $proxima-nova-regular;
                        font-size: 12px;
                    }
                }
            }
        }
        .lj-view-more-button-container {
            .lj-view-more {
                color: #324047;
                font-family: $proxima-nova-regular;
            }
        }
    }
    .filters {
        .filter-label {
            color: #324047;
            font-family: $proxima-nova-regular;
        }
    }
    .osb-light-dealer-search-section-wrapper {
        .select-a-dealer-title-column {
            color: #324047;
            font-family: $proxima-nova-regular;
        }
    }
}
