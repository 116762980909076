@import './dealer-landing-page-ford.scss';
.brand-lincoln {
    .new-dealer-step-view-container {
        .dealer-landing-screen {
            .new-light-dealer-step-wrapper {
                .light-journey-title {
                    color: $brand-primary;
                    font-family: $proxima-nova-regular;
                }
            }
            .new-light-dealer-flow-container-wrapper {
                .preferred-dealer-container {
                    .single-preferred-dealer {
                        color: $brand-primary;
                        font-family: $proxima-nova-regular;
                    }
                    .multi-preferred-dealer {
                        .radio-button-container {
                            font-family: $proxima-nova-regular;
                            background-color: $fds-color-surface;
                            color: $brand-primary;
                            .radio-style {
                                color: $brand-primary;
                                accent-color: $brand-primary;
                            }
                        }
                    }
                }
                .light-dealer-search-container {
                    .osb-use-location {
                        button {
                            background-color: $fds-color-surface;
                        }
                    }
                }
                .vertical-line {
                    @include desktop {
                        color: $fds-color-dk-gray;
                        font-family: $proxima-nova-regular;
                    }

                    &::before {
                        @include desktop {
                            border-left: 1px solid $fds-color-border-md-gray;
                        }
                    }
                    &::after {
                        @include desktop {
                            border-left: 1px solid $fds-color-border-md-gray;
                        }
                    }
                }
                .light-dealer-login-container {
                    .delaer-login-section {
                        .login-instruction {
                            ul {
                                li {
                                    color: $fds-color-dk-gray;
                                    font-family: $proxima-nova-regular;
                                    &::marker {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .retrieve-flow-cta {
                background-color: #fff;
                p {
                    color: $fds-color-dk-gray;
                    font-family: $proxima-nova-regular;
                }
            }
        }
    }
}
