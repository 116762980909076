@import '../../../../../../styles/main.scss';
.dealer-filter-options-container {
    .light-dealer-filter-options-container {
        @media only screen and (max-width: 768px) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2004;
            background-color: #fff;
            margin: 0;
            overflow: hidden;
        }
        .filters-header-container {
            display: flex;
            justify-content: space-between;
            color: #00095b;
            margin: 0 20px;
            height: 60px;
            @media only screen and (max-width: 768px) {
                padding: 15px 15px 15px 25px;
                margin: 0;
            }
            .filters-header-reset {
                display: flex;
                flex-direction: row;
                align-items: center;
                @media only screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: row;
                }
                .filters-header {
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $f1-font-regular;
                    color: $fm-text-grey;
                    text-transform: capitalize;
                    font-weight: 700;
                    @media only screen and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 1.63;
                        color: $brand-mild-gray1;
                    }
                }
                .filters-reset {
                    margin: 30px 25% 30px;
                    text-decoration: underline;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 1.63;
                    color: $fm-breadcrumb-blue;
                    cursor: pointer;
                    @media only screen and (max-width: 768px) {
                        margin: 0px 0 0 16px;
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }
            .filter-close {
                cursor: pointer;
                margin-top: 3%;
                margin-right: 3%;
                .close-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .accordion-filter-container {
            max-height: 425px;
            overflow-y: auto;
            padding-right: 30px;
            @media only screen and (max-width: 768px) {
                max-height: none;
                overflow-y: hidden;
                padding: 0;
                margin: 0 15px;
            }

            .accordion-title {
                font-family: $f1-font-bold;
                font-size: 16px;
                line-height: 24px;
                color: $fm-text-grey;
                text-transform: capitalize;
                .fds-arrow {
                    color: $fm-breadcrumb-blue;
                }
            }
        }
        .accordion-filter-container::-webkit-scrollbar {
            width: 5px;
            height: 230px;
        }

        .accordion-filter-container::-webkit-scrollbar-thumb {
            width: 5px;
            background-color: #4d4d4d;
        }
        .services-list {
            .osb-check-box-spacing {
                display: flex;
                justify-content: space-between;
                margin-left: 30px;

                .checkbox-content {
                    display: flex;
                    @include mobile {
                        text-align: left;
                    }
                    .service-description {
                        text-align: left;
                        label {
                            vertical-align: middle;
                        }
                        .checkbox-label {
                            font-size: 13px;
                            @media screen and (max-width: 320px) {
                                font-size: 13px;
                            }
                        }
                    }
                }
                .tooltip .tooltip__infoIcon {
                    &.dark {
                        -webkit-text-fill-color: $fm-breadcrumb-blue;
                        font-size: 16px;
                        color: $fm-breadcrumb-blue;
                        background-color: #fff;
                        @include mobile {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .hr-line-style {
            border-bottom: 1px solid #6e6e6e;
            margin: 0 30px 0 0;
            @include mobile {
                margin: 0;
            }
        }
        .full-hr-line-style {
            border-bottom: 1px solid $ghost-grey;
            @include mobile {
                margin: 0;
            }
        }
        .apply-button-container {
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            @media only screen and (max-width: 768px) {
                width: 100%;
                position: fixed;
                bottom: 0;
                background-color: #fff;
            }
            .apply-button-style {
                cursor: pointer;
                display: flex;
                justify-content: center;
                margin: 18px 0 20px 0;
                @media only screen and (max-width: 768px) {
                    margin: 18px 0 40px 0;
                }
            }
            .no-dealer-message {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                color: #c43f22;
                margin: 20px 0 0 0;
                @media only screen and (max-width: 768px) {
                    padding: 0 25px 0 25px;
                }
            }
        }
    }
}
