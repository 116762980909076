@import '../../../../../styles/main.scss';

.osb-use-location {
    margin-top: 0px;
    @include mobile {
        margin-top: 24px;
    }

    width: fit-content;
    button {
        line-height: 3rem;
        margin: 0;
        display: flex;
        text-align: left;
        width: 100%;
        border: none;
        background-color: #ffffff;
        cursor: pointer;
        @include tablet-portrait {
            text-align: center;
            justify-content: center;
        }

        img {
            height: 25px;
            @include tablet-portrait {
                margin-top: 3px;
            }
        }
        div {
            margin: 3px 0 0 8px;
            font-size: 14px;
            line-height: 20px;
            color: $fm-skyview;
            font-family: $f1-font-regular;
            text-decoration: underline;
            @include tablet-portrait {
                font-size: 16px;
                line-height: 1.63;
            }
        }
        .icon-navigation {
            content: \e687;
            vertical-align: middle;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    hr {
        box-shadow: none;
    }
}
