@import './light-booking-details-ford.scss';

.brand-lincoln {
    .osb-light-booking-details-section {
        .osb-light-booking-details-header {
            font-family: $proxima-nova-semi-bold;
            color: $brand-secondary;
        }

        .osb-light-booking-details-container {
            .service-section,
            .vehicle-section {
                .service-title,
                .vehicle-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .service-sub-title {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .services-list::-webkit-scrollbar-thumb {
                    background-color: #6e6e6e;
                }
                .service-name {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .vehicle-name {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
            }
            .where-section {
                .where-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .dealer-name,
                .dealer-address,
                .dealer-postalcode {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }
            .when-section {
                .when-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .date-time {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }
            .booking-number-section {
                .booking-number-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .booking-number {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }
            .access-code-section {
                .access-code-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .access-code {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }

            .links-section {
                .print-booking-details {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }

            .link-style {
                color: #4f4c43;
            }

            .hr-line-style {
                content: ' ';
                display: block;
                border-bottom: 1px solid #6e6e6e;
                margin: 30px 0 0;
            }

            .hr-line {
                content: ' ';
                display: block;
                border-bottom: 1px solid #6e6e6e;
                margin: 0;
            }
        }
    }
}
