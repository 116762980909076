@import './osb-service-renderer-ford.scss'; // Default Ford style

.brand-lincoln {
    .osb-service-checkbox-wrapper {
        .radio-style {
            accent-color: #f26147;
        }
        .checkbox {
            &:before {
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-color: #324047;
                border-radius: 0;
            }
            &:checked {
                &:before {
                    content: '\2714';
                    width: 20px;
                    height: 20px;
                    font-size: 16px;
                    border-color: #f26147;
                    background-color: #f26147;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;
                }
            }
        }
        .checkbox-content {
            .service-description {
                .tooltip {
                    .tooltip__infoIcon {
                        &.dark {
                            -webkit-text-fill-color: #324047;
                        }
                    }
                }
                .offer-label {
                    background-color: #f26147;
                }
                .checkbox-label,
                .service-disclaimers {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
            }
            .price-details {
                color: #324047;
                .hasDiscount {
                    color: #f26147;
                }
            }
        }
    }
}
