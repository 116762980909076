@import '../../../../../../styles/main.scss';
.new-dealer-step-view-container {
    .dealer-landing-screen {
        width: 80%;
        margin: auto;
        @include mobile {
            width: 100%;
            margin: 0;
        }
        button {
            font-size: 18px;
        }
        .new-light-dealer-step-wrapper {
            margin: 20px auto;
            @include mobile {
                width: 100%;
                margin: 0;
            }
            .light-journey-title {
                width: 100%;
                margin: auto;
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 24px;
                letter-spacing: 0;
                line-height: 34px;
                text-align: center;
                @include mobile {
                    font-size: 20px;
                    line-height: 30px;
                    padding: 20px 20px 0;
                }
            }
        }
        .new-light-dealer-flow-container-wrapper {
            margin: 30px auto;
            padding: 20px 30px;
            display: flex;
            background-color: $fds-color-surface;
            @include mobile {
                width: 100%;
                margin: 0;
                // padding: 25px;
                display: block;
                background-color: $fds-color-white;
            }
            .preferred-dealer-container {
                width: 50%;
                @include mobile {
                    width: 100%;
                    // margin: 30px 0;
                }
                .preferred-dealer-title {
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 30px;
                }
                .single-preferred-dealer {
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    text-transform: uppercase;
                    transition: visibility 0s, opacity 0.5s linear;
                }
                .multi-preferred-dealer {
                    transition: visibility 0s, opacity 0.5s linear;
                    .radio-button-container {
                        padding: 0;
                        border: none;
                        font-size: 16px;
                        font-family: $f1-font-regular;
                        max-width: 100% !important;
                        line-height: 35px;
                        background-color: $fds-color-surface;
                        color: $fm-text-grey;
                        font-weight: 700;
                        @include mobile {
                            background-color: $fds-color-white;
                        }
                        .radio-style {
                            margin-right: 15px;
                            margin-top: -2px;
                            color: $fm-skyview;
                            accent-color: $fm-skyview;
                            min-width: 16px;
                            min-height: 16px;
                        }
                    }
                }
                .continue-btn {
                    margin: 30px 0;
                    text-align: center;
                    button {
                        height: 40px;
                        padding: 0 50px;
                        font-size: 14px;
                        width: 100%;
                        display: block;
                        &::after {
                            background-color: $fm-skyview;
                        }
                    }
                }
            }
            .light-dealer-search-container {
                width: 50%;
                @include mobile {
                    width: 100%;
                }
                .search-float-right {
                    float: right;
                    .autocomplete-box {
                        width: 400px;
                    }
                }
                .osb-use-location {
                    margin: 20px 0;
                    div {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    button {
                        background-color: $fds-color-surface;
                        @include mobile {
                            background-color: $fds-color-white;
                        }
                    }
                }
            }
            .vertical-line {
                @include desktop {
                    position: relative;
                    height: 150px;
                    width: 67px;
                    display: block;
                    text-align: center;
                    padding-top: 70px;
                    margin: 10px auto;
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 22px;
                }

                @include mobile {
                    --text-divider-gap: 1rem;
                    display: flex;
                    align-items: center;
                    font-size: 0.9375rem;
                    letter-spacing: 0.1em;
                    margin: 30px 0;
                }
                &::before {
                    @include desktop {
                        border-left: 1px solid $fds-color-lt-gray-b;
                        content: '';
                        display: block;
                        height: 70px;
                        left: 30px;
                        position: absolute;
                        top: 0px;
                        width: 1px;
                        z-index: 0;
                    }

                    @include mobile {
                        content: '';
                        height: 1px;
                        background-color: silver;
                        flex-grow: 1;
                        margin-right: var(--text-divider-gap);
                    }
                }
                &::after {
                    @include desktop {
                        border-left: 1px solid $fds-color-lt-gray-b;
                        content: '';
                        display: block;
                        height: 70px;
                        left: 30px;
                        position: absolute;
                        top: 90px;
                        width: 1px;
                        z-index: 0;
                    }

                    @include mobile {
                        content: '';
                        height: 1px;
                        background-color: silver;
                        flex-grow: 1;
                        margin-left: var(--text-divider-gap);
                    }
                }
            }
            .light-dealer-login-container {
                width: 50%;
                @include mobile {
                    width: 100%;
                }
                .dealer-login-section {
                    float: right;
                    @include mobile {
                        float: none;
                    }
                    .login-btn {
                        button {
                            height: 40px;
                            font-size: 14px;
                            &::after {
                                background-color: $fm-skyview;
                            }
                        }
                    }
                    .login-instruction {
                        margin-top: 30px;
                        @include mobile {
                            padding: 0;
                        }
                        ul {
                            list-style-position: outside;
                            padding: 0 5px;
                            li {
                                list-style-type: none;
                                color: $fm-text-grey;
                                font-family: $f1-font-regular;
                                font-size: 14px;
                                line-height: 24px;
                                padding: 0 5px;
                                &::marker {
                                    content: '\2022';
                                    color: $fds-color-primary;
                                    padding: 0 5px;
                                    margin: 20px;
                                    width: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }
        .retrieve-flow-cta {
            margin-top: 60px;
            display: flex;
            background-color: #fff;
            p {
                width: 80%;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 20px;
                padding: 20px;
            }
            .retrieve-btn {
                width: 20%;
                float: right;
                padding: 12px;
                button {
                    font-size: 14px;
                    &::after {
                        background-color: $fm-skyview;
                    }
                }
            }
        }
    }
}
