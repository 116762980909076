@import '../../../../../styles/main.scss';

.light-cancel-booking-confirmation-component-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4%;
    background-color: #fff;
    font-size: 20px;

    @media only screen and (max-width: 1050px) {
        width: 100%;
        padding: 0 30px;
    }
    .cbc-head {
        font-family: $f1-font-light;
        font-size: 120%;
        line-height: 1.42;
        text-align: center;
        color: #00095b;
        padding-top: 50px;
        padding-bottom: 21px;
        text-transform: uppercase;

        @include mobile {
            font-family: $f1-font-regular;
        }

        @include tablet-portrait {
            text-align: left;
            line-height: 1.5;
            color: #09315a;
            font-size: 100%;
            padding-top: 28px;
        }
    }

    .cbc-text {
        font-family: $f1-font-regular;
        font-size: 80%;
        line-height: 1.63;
        text-align: center;
        color: #4d4d4d;
        padding-bottom: 20px;

        @include tablet-portrait {
            text-align: left;
            padding-top: 12px;
        }
    }

    .cbc-box-content {
        padding: 24px;
        background-color: #f4f4f4;
        margin-bottom: 45px;
        @include tablet-portrait {
            margin-bottom: 22px;
            margin-left: -30px;
            margin-right: -40px;
        }
        .body-content {
            p {
                font-family: $f1-font-regular;
                font-size: 80%;
                line-height: 1.63;
                color: #4d4d4d;
                text-align: left;
            }
        }
    }

    .cbc-footer-content-container {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-bottom: 40px;

        &.auth-flow-container {
            grid-template-columns: 100%;
        }

        @include tablet-portrait {
            grid-template-columns: 100%;
        }

        .cbc-footer-rebook,
        .cbc-footer-rebook-auth-flow {
            position: relative;
            .sub-head {
                font-family: $f1-font-regular;
                font-size: 100%;
                line-height: 1.7;
                color: #00095b;
                text-transform: uppercase;
                margin-bottom: 14px;
            }
            .rebook-text {
                font-family: $f1-font-regular;
                font-size: 80%;
                line-height: 1.63;
                color: #4d4d4d;
                padding-right: 80px;

                span > p > a {
                    color: #00095b;
                }
                @include tablet-portrait {
                    padding: 0;
                }
            }
            .vehicle-step-link {
                color: #042361;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .cbc-footer-rebook::after {
            content: '';
            position: absolute;
            border-left: 2px solid #f4f4f4;
            right: 10px;
            height: 100%;
            bottom: 0;
            @include tablet-portrait {
                content: none;
            }
        }

        .cbc-footer-ford-account {
            margin-left: 80px;

            @include tablet-portrait {
                margin-left: 0;
            }
            .ford-account-section {
                margin-top: 0;
                @include tablet-portrait {
                    margin-top: 30px;
                }
                .ford-account-title {
                    font-family: $f1-font-regular;
                    font-size: 100%;
                    line-height: 1.5;
                    color: #00095b;
                    text-transform: uppercase;
                }

                .ford-desc-container {
                    font-family: $f1-font-regular;
                    margin: 0;
                    font-size: 80%;
                    line-height: 1.63;
                    color: #4d4d4d;
                }

                .ford-account-desc {
                    padding-bottom: 30px;
                }

                .ford-button-container {
                    font-size: 70%;
                    grid-template-columns: 30% 20% 50%;
                    @include tablet-portrait {
                        grid-template-columns: 100%;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
