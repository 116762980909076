@import '../../../../../styles/main.scss';

.osb-file-uploader-container {
    .osb-fu-main-title-container {
        margin-top: 40px;
        width: 100%;
        display: block;
        .osb-fu-main-title {
            font-family: $f1-font-medium;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 32px;
            color: $breadcrumb-blue;
        }

        .tooltip .tooltip__infoIcon {
            &.dark {
                -webkit-text-fill-color: #00095b;
                font-size: 22px;
                top: -1px;
                background-color: #fff;
            }
        }
    }

    .osb-drag-drop-container {
        margin-top: 30px;

        @include xs-mobile {
            margin-top: 20px;
        }
        @include tablet-portrait {
            margin-top: 20px;
        }

        .osb-drag-drop-title {
            padding-bottom: 10px;
            font-family: $f1-font-regular;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 20px;
            color: $fm-text-grey;
        }

        .osb-drag-drop-panel {
            height: 50px;
            width: 85%;
            border-radius: 8px;
            border: 1px solid $grey;
            display: table-cell;
            vertical-align: middle;
            background-color: $fds-color-white;
            @include tablet-portrait {
                height: 20px;
            }

            @include xs-mobile {
                height: 20px;
            }
            @include mobile {
                border: 0px;
            }
            div {
                padding-left: 10px;
                font-family: $f1-font-regular;
                font-size: 16px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: #4d4d4d;
                @include tablet-portrait {
                    padding-left: 5px;
                }
                @include mobile {
                    padding-left: 0px;
                }

                #osbInputFileUpload {
                    display: none;
                }

                .osb-fu-upload-link {
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    font-weight: 400;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .osb-drag-drop-valid-file-label {
            padding-top: 10px;
            font-family: $f1-font-regular;
            font-size: 12px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 16px;
            color: $darkgrey;
        }
    }
    .osb-fu-status-grid-container {
        width: 100%;
        .osb-fu-selected-files {
            display: grid;
            grid-template-columns: 25px 200px 80px auto;
            padding-top: 20px;
            padding-left: 10px;

            @include tablet-portrait {
                grid-template-columns: 25px 150px 80px auto;
                min-height: 50px;
                height: auto;
                padding-left: 5px;
            }

            @include xs-mobile {
                grid-template-columns: 25px 120px 70px auto;
                min-height: 50px;
                height: auto;
            }

            .osb-fu-selected-file-status {
                margin-top: 2px;
                background: url(../../../../../assets/owners-osb/success-tick.png)
                    no-repeat;

                @include tablet-portrait {
                    margin-top: 6px;
                }

                @include xs-mobile {
                    margin-top: 6px;
                }
            }

            .osb-fu-selected-file-name {
                padding-right: 10px;
                font-family: $f1-font-regular;
                font-size: 11px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #4d4d4d;
                overflow-wrap: break-word;
            }

            .osb-fu-selected-file-remove {
                font-family: $f1-font-regular;
                font-size: 11px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #00095b;
                text-decoration: underline;
                cursor: pointer;
                overflow-wrap: break-word;
            }

            .osb-fu-selected-file-confirm-remove {
                font-family: $f1-font-regular;
                font-size: 11px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #4d4d4d;
                padding-top: 2px;
                word-wrap: break-word;
            }

            .osb-fu-selected-file-view {
                font-family: $f1-font-regular;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #00095b;
                text-decoration: underline;
                cursor: pointer;
                word-wrap: break-word;
                margin-left: 15px;
            }

            .osb-fu-selected-file-remove-decision {
                padding-right: 10px;
                vertical-align: middle;
                font-family: $f1-font-regular;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #00095b;
                text-decoration: underline;
                cursor: pointer;
                word-wrap: break-word;
                margin-left: 5px;
            }
        }

        .osb-fu-rejected-files {
            display: grid;
            grid-template-columns: 25px 200px auto;
            padding-top: 20px;
            padding-left: 10px;

            @include tablet-portrait {
                grid-template-columns: 25px 150px auto;
                min-height: 50px;
                height: auto;
                padding-left: 5px;
            }

            @include xs-mobile {
                grid-template-columns: 25px 100px auto;
                min-height: 50px;
                height: auto;
            }

            .osb-fu-rejected-file-status {
                background: url(../../../../../assets/owners-osb/warning.png)
                    no-repeat;

                @include tablet-portrait {
                    margin-top: 6px;
                }

                @include xs-mobile {
                    margin-top: 6px;
                }
            }

            .osb-fu-rejected-file-name {
                padding-right: 10px;
                font-family: $f1-font-regular;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #4d4d4d;
                overflow-wrap: break-word;
            }

            .osb-fu-rejected-message {
                font-family: $f1-font-regular;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #d62d0a;
            }
        }
    }
}
