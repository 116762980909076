@import './timeslot-picker-ford.scss';

.brand-lincoln {
    .osb-timeslot-picker-container {
        .main-head {
            font-family: $proxima-nova-regular;
            color: #000;
        }
        .time-range-main-head {
            font-family: $proxima-nova-regular;
            color: #000;
        }
        .timeslot-picker {
            // border: 1px solid #6e6e6e;
            @include tablet-portrait {
                box-shadow: 0 16px 16px -5px #6e6e6e;
                // border: 1px #6e6e6e solid;
            }
            .timeslot-label {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }

            .radio-toolbar {
                label {
                    border: solid 1px #324047;
                    color: $brand-secondary;
                }
                label:hover {
                    background: $brand-secondary;
                    color: #f4f4f4;
                    cursor: pointer;
                }

                .radio-custom:focus ~ label {
                    border: 2px solid #4f4c43;
                }
                .radio-custom:checked ~ label {
                    background: $brand-secondary;
                    color: #f4f4f4;
                }
            }
        }
    }
}
