@import '../../../../../../styles/main.scss';
.osb-dealer-details-mobile-map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .osb-map-container {
        position: relative;
        height: 60vh;
        z-index: 0;
    }
    .mobile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-left: 10px;
        .dealer-tiles-container {
            display: flex;
            overflow: auto;
            scroll-snap-type: x mandatory;
            transform: translateY(-35%);
            left: 10px;
            width: 100%;
            z-index: 0;
            .dealer-info-box-wrapper {
                position: relative;
                border-radius: 10px;
                width: 90%;
                scroll-snap-align: start;
                margin-right: 1rem;
                z-index: 1;
                display: grid;
                grid-gap: 22.5px;
                .preferred-dealer-label {
                    position: absolute;
                    margin: 0 auto;
                    z-index: 1;
                    grid-row: 1 / 2;
                    color: $fds-color-white;
                    background-color: $fds-color-primary;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    font-family: $f1-font-regular;
                    font-size: 11px;
                    line-height: 17px;
                    width: fit-content;
                    padding: 5px 10px 0;
                }
                .dealer-info-box-container {
                    grid-row: 2 / 2;
                    background-color: $fds-color-white;
                    padding: 15px;
                    border-radius: 3px;
                }
                .dealer-info-box-container.with-dealer-capability {
                    height: 327px;
                }
                .dealer-info-box-container.without-dealer-capability {
                    height: 230px;
                }
            }
        }
    }
    .view-more-container-mobile {
        align-self: center;
        display: flex;
        align-items: center;
        .view-more-button-mobile {
            position: absolute;
            bottom: 29px;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            .view-more-text-mobile {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 24px;
                padding-right: 15px;
            }
            .fds-chevron-wrap {
                .fds-chevron {
                    color: $fm-breadcrumb-blue;
                }
            }
        }
    }
    .search-list-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 460px;
        height: 100%;
        background-color: transparent;
        z-index: 1;
        overflow-y: auto;
        margin-right: 20px;
        .filter-container {
            margin-top: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        .dealer-info-box:first-of-type {
            margin-top: 20px;
        }

        /* Customize the scrollbar */
        &::-webkit-scrollbar {
            width: 3px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: $fds-color-lt-gray;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $quicklink-textactive;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $quicklink-textactive;
        }
    }
    .map-actions {
        position: relative;
        top: 76px;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 15px;
        &.fixed {
            position: fixed;
            top: 199px;
        }
        .filter-link,
        .list-link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $fds-color-white;
            color: $fm-breadcrumb-blue;
            border: 1px solid $fm-breadcrumb-blue;
            border-radius: 20px;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
            margin-right: 14px;
            margin-bottom: 30px;
            width: 40%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2),
                0px 0px 6px rgba(0, 0, 0, 0.2);
            .filters-text,
            .list-text {
                color: $fm-breadcrumb-blue;
                font-family: $f1-font-regular;
                font-size: 14px;
                font-weight: 700;
                margin: auto;
            }
        }
    }
}
