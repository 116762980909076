@import '../../../../../styles/main.scss';
.datetime-step-view-container {
    background-color: #ffffff;

    .osb-light-journey-datetime-page {
        width: 100%;
        display: grid;
        margin: 0 auto;
        grid-template-columns: 65% 33%;
        grid-column-gap: 2%;

        @media only screen and (max-width: 1200px) {
            margin: 0 auto;
        }

        @media only screen and (max-width: 999px) and (min-width: 769px) {
            display: flex;
            flex-direction: column;
        }

        @include tablet-portrait {
            display: block;
            width: 100%;
            grid-template-columns: 100%;
            grid-column-gap: 0;
        }

        .osb-datetime-container {
            height: auto;
            background-color: $scard-light-grey;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 30px;
            padding: 50px;
            border-radius: 16px;

            .error-message {
                color: rgb(214, 45, 20);
                font-size: 0.75rem;
                white-space: break-spaces;

                @include mobile {
                    padding: 0;
                }
            }

            @media only screen and (max-width: 1200px) {
                padding: 30px;
            }
            @include xxl-desktop {
                font-size: 20px;
            }

            @include tablet-portrait {
                // padding: 20px 25px;
                font-size: 16px;
            }

            @include mobile {
                font-size: 12px;
            }

            @include xs-mobile {
                font-size: 10px;
            }
            @media only screen and (max-width: 280px) {
                padding: 20px 15px;
            }

            .datetime-title {
                font-family: $f1-font-regular;
                font-size: 40px;
                line-height: 48px;
                font-weight: 400;
                text-align: left;
                color: #00095b;
                margin: 0px;
                margin-bottom: 30px;
                @include tablet-portrait {
                    width: auto;
                    height: auto;
                    text-align: left;
                    font-size: 32px;
                    line-height: 40px;
                    margin-top: 12px;
                }
            }
            .cal-slot-section {
                display: grid;
                @include mobile {
                    display: block;
                }
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    grid-template-columns: 55% 45%;
                    grid-gap: 5%;
                }
                @media only screen and (min-width: 1201px) {
                    grid-template-columns: 50% 45%;
                    grid-gap: 5%;
                }
                @include tablet-portrait {
                    grid-template-columns: 100%;
                    grid-gap: 0;
                }
                .input-field {
                    width: 100%;
                }
            }

            .osb-calendar-container-panel {
                height: auto;
                display: grid;
                white-space: nowrap;
                margin-top: 30px;
                @media only screen and (min-width: 768px) and (max-width: 1200px) {
                    grid-template-columns: 55% 45%;
                    grid-gap: 5%;
                }
                @media only screen and (min-width: 1201px) {
                    grid-template-columns: 50% 45%;
                    grid-gap: 5%;
                }
                @include tablet-portrait {
                    grid-template-columns: 100%;
                    grid-gap: 0;
                }
                .calendar-container {
                    margin-top: 30px;
                }

                @include tablet-portrait {
                    .input-field,
                    .input-field-error {
                        width: 100%;
                    }
                    .input-field {
                        border: 1px solid $grey;
                        border-radius: 8px;
                        height: 40px;
                        color: $fm-text-grey;
                        @include tablet-portrait {
                            order: 3;
                        }
                    }
                    .osb-error-message {
                        top: -1.8rem;
                        color: rgba(214, 45, 20, 1);
                        font-size: 0.75rem;
                        line-height: 1.313rem;
                    }
                    .input-label {
                        font-size: 14px;
                        @include tablet-portrait {
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;
                            order: 2;
                        }
                    }
                }
                .cal-main-head {
                    font-family: $f1-font-medium;
                    font-size: 24px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 32px;
                    letter-spacing: 0px;
                    color: $fds-color-primary;
                    margin-bottom: 20px;
                    white-space: break-spaces;
                    @include tablet-portrait {
                        font-size: 16px;
                        order: 1;
                        margin: 0;
                        margin-top: 25px;
                    }
                }
                .selected-date {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    color: $fm-text-grey;
                    white-space: break-spaces;
                    margin-bottom: 30px;
                    @include tablet-portrait {
                        order: 2;
                    }
                }

                @include tablet-portrait {
                    margin-top: 0;
                }
                .radio-toolbar {
                    padding: 20px;
                    @media only screen and (max-width: 280px) {
                        padding: 0;
                    }
                    @include mobile {
                        padding: 20px 0;
                    }
                }

                .timeslot-component {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .main-head {
                        font-family: $f1-font-medium;
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: 0px;
                        color: #00095b;
                        @include mobile {
                            text-transform: uppercase;
                            color: $fm-text-grey;
                        }
                        @include tablet-portrait {
                            font-size: 16px;
                            margin-top: 30px;
                            order: 5;
                        }
                    }
                    .input-label {
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        margin-bottom: 43px;
                        line-height: 26px;
                        padding: 0px !important;
                    }
                }

                .selected-date-empty {
                    margin-bottom: 15px;
                }

                .timeslot-label,
                .timerange-label {
                    display: flex;
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    color: $fm-text-grey;
                    align-items: flex-end;
                    white-space: break-spaces;
                    @include tablet-portrait {
                        order: 6;
                        padding: 22px 0 17px 0;
                    }
                }

                .timeslot-empty-label {
                    padding-top: 25px;

                    @include tablet-portrait {
                        order: 6;
                        padding-top: 15px;
                    }
                }
            }

            .button-container {
                display: grid;
                white-space: nowrap;
                grid-template-columns: 30% 40%;
                margin: 45px 0 40px 0;

                @include tablet-portrait {
                    grid-template-columns: 100%;
                    margin: 50px 0;
                    order: 8;
                }

                .back-button {
                    @include tablet-portrait {
                        margin: 0 180px;
                        padding-bottom: 10px;
                    }
                    @include mobile {
                        margin: 0 50px;
                    }
                }

                .back-button-mobile {
                    margin: 10px 0;
                    ul {
                        cursor: pointer;
                        list-style: none;
                        font-size: 18px;
                        color: $fm-breadcrumb-blue;
                    }
                    li {
                        padding-right: 5px;
                        display: inline-block;
                    }
                }

                .primary-button {
                    outline: none;

                    @include tablet-portrait {
                        width: 100%;
                        display: block;
                    }
                }
            }
            .footer-text {
                width: auto;
                height: auto;
                font-size: 16px;
                font-family: $f1-font-regular;
                line-height: 26px;
                color: $fm-text-grey;
                @include tablet-portrait {
                    height: auto;
                    order: 9;
                }
                p {
                    margin-bottom: 25px;
                }
            }
            .call-dealer-link {
                color: $fm-breadcrumb-blue;
                font-size: 16px;
                text-decoration: underline;
                cursor: pointer;
                margin-top: 20px;
            }
            .osb-secondary-button {
                padding-left: 0;
                @include mobile {
                    padding-bottom: 20px;
                }
            }
        }
        .osb-pickup-service-calendar-container {
            background-color: #ffffff;
            margin-bottom: 30px;
            @include xxl-desktop {
                font-size: 20px;
            }

            @include tablet-portrait {
                font-size: 14px;
            }

            @include mobile {
                font-size: 12px;
            }

            @include xs-mobile {
                font-size: 10px;
            }
            .pickup-calendar-continue-button {
                padding-top: 5px;
                padding-left: 50px;
                margin: 20px 0 50px 0;
                @include mobile {
                    padding-left: 25px;
                    padding-bottom: 58px;
                    padding-top: 23px;
                }
            }
        }
    }
    .dealer-call-cta-popup-content {
        width: 200px !important;
        height: 50px !important;
        padding: 13px !important;
        background-color: #ffffff !important;
        border: 1px solid #949699;
        color: #00095b;
        border-radius: 5px;
    }

    .dealer-call-cta-popup-arrow {
        color: #fff !important;
        stroke-width: 2px !important;
        stroke: #6e6e6e !important;
        stroke-dasharray: 30px !important;
        stroke-dashoffset: -54px !important;
    }
}
