@import './osb-alert-message-popup-ford.scss';

.brand-lincoln {
    .alert {
        z-index: 9999;
        .error-text {
            p {
                color: #324047;
                font-family: $proxima-nova-bold;
            }
        }
        /* The close button */
        .closebtn {
            color: #324047;
            font-family: $proxima-nova-bold;
        }
    }
}
