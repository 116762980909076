@import './calendar-ford.scss';

.brand-lincoln {
    /* Calendar */
    .new-osb-calendar-container {
        .calendar {
            border: 1px solid lightgray;
            .cal-header {
                font-family: $proxima-nova-semi-bold;
                color: #000;
                .icon:hover {
                    color: $brand-border;
                }
            }
            .days {
                font-family: $proxima-nova-semi-bold;
                color: $brand-secondary;
                @include tablet-portrait {
                    color: #000;
                }
            }
            .body {
                .cell {
                    @include tablet-portrait {
                        color: $brand-secondary;
                    }
                    &:hover {
                        background: $brand-secondary;
                        color: #f4f4f4;
                        border-radius: 5px;
                    }
                    .currentDay .number {
                        background: $brand-secondary;
                        color: #fff;
                        font-family: $proxima-nova-regular;
                    }
                    .selected {
                        color: $brand-secondary;
                        border: solid 1px $brand-secondary;
                        font-family: $proxima-nova-regular;
                    }
                    .bg {
                        color: $brand-secondary;
                    }
                }
                .selected {
                    background: $brand-secondary;
                    color: #f4f4f4;
                }
                .disabled {
                    color: #ecedee;
                }
            }
        }
    }
    .new-osb-calendar-container .calendar .body .currentDay .number {
        background: #324047;
    }
}
